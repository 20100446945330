import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux'

import { getCourses, clearErrors } from '../../actions/courseActions'
import { getPromos, clearErrors as clearPromoErrors } from '../../actions/promoActions';

import { REVIEW_DETAILS_RESET } from '../../constants/reviewConstants'

import { initGA, logPageView } from '../../GoogleAnalytics';

import CourseCard from './courseCard';

const Academy = () => {
    const dispatch = useDispatch();

    const { loading, error, courses } = useSelector(state => state.getCourses);
    const { error: promoError, promos } = useSelector(state => state.getPromos);

    const activePromotions = promos.filter(promo => new Date() >= new Date(promo.startDate) && new Date() <= new Date(promo.endDate))   

    const freeCourses = courses.filter(course =>!course.hidden && course.price === 0);
    const paidCourses = courses.filter(course =>!course.hidden && course.price > 0);

    useEffect(() => {
        dispatch(getCourses());
        dispatch(getPromos());
        dispatch({ type: REVIEW_DETAILS_RESET })
        initGA();
        logPageView();

        if (promoError) {
            toast.error("Error fetching promos.")
            dispatch(clearPromoErrors())
        }

        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }

    }, [dispatch, error]);

  return (
    <Fragment>
        <Metadata title={'Academy'} />
        <ToastContainer/>
            <div className="carousel-image mt-5" style={{backgroundImage: "url('/images/academy_bg.png')", backgroundPosition: "top", backgroundSize: "cover"}}></div>
            <div className="carousel-text d-block">
                <h5 className="carousel-header bold-2">Learn to cook with Omoye</h5>
                <p className="text-sm py-2">Full courses to make you master the art of cooking.</p>
                {/* <hr className="w-100"/> */}
            </div>

            <div className="container-fluid container-md">
                {/* HERO TEXT */}
                <div className="text-center my-5">
                    <h2 className="text-lg">DAILY, WEEKLY & MONTHLY FULL COURSES TO TAKE YOU FROM BEGINNER TO A MAESTRO IN THE KITCHEN</h2>
                    <hr className="mx-auto w-25" />
                    <p className="text-xs spaced my-4"> We have carefully crafted out cooking classes to suit your needs. 
                    Each class contains detailed videos on how to make the outlined meals. The videos are so detailed and come with access to telegram chat groups for questions and answers. 
                    All we ask is that you come ready and you’ll be glad you signed up.</p>
                </div> 

                {loading ?
                    <div className="my-5">
                        <Skeleton count={1} height={300} baseColor="#dc720082"/>
                    </div> :
                    <Fragment>
                        {/*  FREE COURSES */}
                        { freeCourses.length > 0 ? 
                            <Fragment>
                                <div className="text-center my-5">
                                    <h2 className="text-lg">SNEAK PEAK COURSES TO GET YOU EXCITED!</h2>
                                    <hr className="mx-auto w-25" />
                                    <p className="text-xs spaced mt-4 mb-5">These short courses and free for you to enjoy and get even more excited about all the awesome recipes we will be making in all of our courses!.</p>
                                    <div className="wrapper">
                                        { freeCourses.map(course => (
                                            <CourseCard key={course._id} course={course}/>
                                        ))}
                                    </div> 
                                </div>
                            </Fragment>:<Fragment></Fragment>
                        }

                        {/*  PREMIUM COURSES */}
                        { paidCourses.length > 0 ? 
                            <Fragment>
                                <div className="text-center my-5">
                                    <h2 className="text-lg">PREMIUM COURSES TO GET YOU THERE QUICKLY!</h2>
                                    <hr className="mx-auto w-25" />
                                    <p className="text-xs spaced mt-4 mb-5">The classes have been categorized under different price points to cater for everyone. All classes gives one on one access to me so you don’t have to worry at all. Look through and find which appeals to you.
                                        My team and I will be here waiting to hold your hand throughout the program.</p>
                                    <div className="wrapper">
                                        { paidCourses.map(course => (
                                            <CourseCard key={course._id} course={course} activePromotions={activePromotions}/>
                                        ))}
                                    </div> 
                                </div>
                            </Fragment>:<Fragment></Fragment>
                        }
                    </Fragment>
                }
            </div>
            
    </Fragment>
  )
}

export default Academy