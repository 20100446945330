import React, { Fragment, useEffect, useState } from 'react'

import Metadata from '../layout/metadata'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { getCourseDetails, clearErrors } from '../../actions/courseActions'
import { getReviews, getReviewDetails } from '../../actions/reviewActions'
import { UPDATE_COURSE_RESET } from '../../constants/courseConstants'

import { formatDate, numberWithCommas } from '../../Functions/functions';

import EnrollModal from './enrollModal'
import ReviewModal from './reviewModal'

import { initGA, logPageView } from '../../GoogleAnalytics';

let userCourses = [];
let userSession = [];
let stayActiveDate = [];

const paystackPK = process.env.REACT_APP_PAYSTACK_PK

const CourseDetails = () => {

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    
    const { isAuthenticated, user } = useSelector(state => state.auth);
    const { loading, error, course } = useSelector(state => state.courseDetails);
    const { success: updateSuccess } = useSelector(state => state.newCourse);
    const { reviews } = useSelector(state => state.getReviews);
    const { review } = useSelector(state => state.reviewDetails);
    
    const price = location.state && location.state.discountedPrice ? location.state.discountedPrice : course.price

    reviews.sort((a, b) => b.rating - a.rating);
    const courseReviews = reviews.filter(review => review.itemId === course._id)

    let allVids = []
    let unlockedVids = [];
    let lockedVids = [];
    let sortedGrouped = [];
    let prevVid;

    if (user && user.courses){
        userCourses = user.courses.filter(course => course.id === params.id).map(course => course.id)
        userSession = user.courses.filter(course => course.id === params.id).map(course => course.session)[0]
        stayActiveDate = user.courses.filter(course => course.id === params.id).map(course => course.stayActiveDate)[0]
    }

    if (course && course.name) {
        allVids = [...course.courseVideos].sort((a, b) => a.unlockOrderHistory[userSession - 1] - b.unlockOrderHistory[userSession - 1])
        prevVid = course.prevVideo

        lockedVids = course.courseVideos.filter(video => !video.unlocked)
        unlockedVids = course.courseVideos.filter(video => video.unlocked).sort((a, b) => a.unlockOrder - b.unlockOrder)

        if (userCourses.includes(params.id) && new Date() >= new Date(stayActiveDate)) {
            unlockedVids = allVids
        }

        sortedGrouped = unlockedVids.reduce((acc, obj) => {
            if (!userSession || userCourses.length === 0) userSession = obj.unlockHistory.length
            const date = obj.unlockHistory[userSession - 1];
            const index = acc.findIndex(item => Object.keys(item)[0] === date);
            if (index === -1) {
                acc.push({ [date]: [obj] });
            } else {
                acc[index][date].push(obj);
            }
            return acc;
            }, [])
            .sort((a, b) => new Date(Object.keys(b)[0]) - new Date(Object.keys(a)[0]));
    
            // This code first uses the reduce() method to group the data by date and maintain the original structure as an array of objects with a single key-value pair representing a date and its corresponding array of objects.
            // It then chains the sort() method to sort the resulting array of objects by date in descending order (i.e., latest to earliest).
            // The sort() method uses a custom comparator function that extracts the date from each object by converting the first key of the object to a Date object.
    }

    useEffect(() => {
        dispatch(getCourseDetails(params.id, "User"));
        dispatch(getReviews("User"));
        

        initGA();
        logPageView();

        if (isAuthenticated) {
            dispatch(getReviewDetails(params.id, "User"));
        }

        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }

        if (updateSuccess) {
            dispatch({type: UPDATE_COURSE_RESET})
        }

    }, [dispatch, isAuthenticated, review._id, error]);

    const handleContextMenu = (e) => {
        e.preventDefault();
    }

    const handleDownload = (name, url) => {
        const pdfUrl = url;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank'; // Open in a new tab
        link.download = name; // Specify the suggested file name
        link.click();
    };

    const goBack = (e) => {
        navigate("/academy");
    }

    const signIn = (e) => {
        toast.error("Please sign in first")
        navigate(`/signin?redirect=academy/${course._id}`)
    }

    return (
        <Fragment>
            <Metadata title={"Course Details"}/>
            <ToastContainer/>
                 
            <Fragment>
                <div className="black-top" style={{backgroundImage: "url('/images/blck.jpg')", backgroundPosition: "top", backgroundSize: "cover"}}>
                    <div className="container-fluid container-md">
                        <div className="py-3 mt-3">
                            <span onClick={goBack} className="breadcrumb text-sm white">{"< Back to Courses"}</span>
                        </div>

                        {loading ? 
                            <Fragment>
                                <div className="my-4 pb-5">
                                    <Skeleton count={1} height={300} baseColor="#dc720082"/>
                                </div>
                            </Fragment> :
                            <div className="row m-0 pb-5 pt-0 pt-md-3">
                                <div className="col-md-6 p-0 white order-2 order-md-1">
                                    <h3 className="text-lg bold-2 mt-4 mt-md-0">{course.name}</h3>
                                    <p className="text-xs mt-3 mb-0">{course.caption}</p>
                                    {price !== course.price ?
                                        <p className="text-xxl mt-3 mb-0 bold-2">&#x20A6;{numberWithCommas(price)} <span className="bold-1 text-md text-decoration-line-through">&#x20A6;{numberWithCommas(course.price)}</span></p>
                                        :<p className="text-xxl mt-3 mb-0 bold-2">&#x20A6;{numberWithCommas(price)}</p>
                                    }

                                    <p className="text-xs mt-3 mb-0"><span className="bold-2">{Math.ceil(course.totalVideoDuration/60)} mins</span> of HD video content {/* • <span className="bold-2">{course.views} views</span> • <span className="bold-2">{course.studentsEnrolled} enrolled</span> */}</p>
                                    <p className="text-xs mt-3 mb-0">Start Date: <span className="orange bold-2">{formatDate(course.startDate)}</span></p>
                                    <p className="text-xs mt-3 mb-0">Course Duration: <span className="orange bold-2">{course.duration}</span></p>
                                    <p className="text-xs mt-3 mb-0">New videos are unlocked: <span className="orange bold-2">{course.schedule}</span></p>
       
                                    {!isAuthenticated ? 
                                        <button onClick={signIn} className="big-orange-btn text-xs mt-5 py-3 bold-2">Enroll now!</button>
                                        : userCourses.includes(params.id) ?  
                                            <Fragment>
                                                <div className="d-inline-block enrolled text-xs mt-5 py-3 bold-2">Enrolled!</div>     
                                                <button data-bs-toggle="modal" data-bs-target="#reviewModal" className="big-orange-btn text-xs mx-2 mt-5 py-3 bold-1">Submit review</button>
                                                {course.doc ?
                                                    <button className='big-orange-btn text-xs mt-3 py-3 bold-1' onClick={() => handleDownload(course.name, course.doc.url)}>Download recipe</button>
                                                : <Fragment></Fragment>}
                                                <ReviewModal props={{itemId: course._id, itemName: course.name, type: "course", userId: user._id, header: review.header, comment: review.comment, rating: review.rating }}/>
                                            </Fragment>                   
                                        :   <Fragment>
                                                <button data-bs-toggle="modal" data-bs-target="#enrollModal" className="big-orange-btn text-xs mt-5 py-3 bold-2">Enroll now!</button>
                                                <EnrollModal props={{course: course, user : user, paystackPK : paystackPK, price: price }}/> 
                                            </Fragment>
                                    }

                                </div>
                                <div className="col-md-6 order-1 order-md-2">
                                    { prevVid ? 
                                        <video onContextMenu={handleContextMenu} className="prev-vid" controls poster={prevVid.thumbnailURL}>
                                            <source src={prevVid.videoURL} type={prevVid.type} />
                                            Your browser does not support the video tag.
                                        </video>
                                    : <Fragment></Fragment>}
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className="container-fluid container-md">
                    {loading ? 
                        <Fragment>
                            <div className="my-5">
                                <Skeleton count={6} height={100} baseColor="#dc720082"/>
                            </div>
                        </Fragment> :

                            <div className="row p-0 my-5">

                                {/* DESCRIPTION */}
                                <div className="col-12 mb-4 mb-md-5">
                                    <h3 className="text-xs bold-2 mb-3">Description:</h3>
                                    <p className="spaced text-xs">{course.description}</p>
                                </div>
                                
                                {/* INCLUDES */}
                                <div className="col-md-6 mb-5 mb-md-0">
                                    <h3 className="text-xs bold-2 mb-3">This course Includes:</h3>
                                    { course.name && course.includes.split('•').slice(1).map(incItem => (
                                        <p key={incItem} className="spaced text-xs my-2 p-0">• {incItem}</p>
                                    ))}
                                </div>

                                {/* REQUIREMENTS */}
                                <div className="col-md-6 ps-auto ps-md-5">
                                    <h3 className="text-xs bold-2 mb-3">Course requirements:</h3>
                                    { course.name && course.requirements.split('•').slice(1).map(reqItem => (
                                        <p key={reqItem} className="text-xs my-2 p-0">• {reqItem}</p>
                                    ))}
                                </div>

                                {/* COMMENTS */}
                                {courseReviews.length > 0 ? 
                                <Fragment>
                                    <h3 className="text-xs bold-2 mt-5">What students are saying about this course:</h3>
                                    <div className="wrapper mt-3">
                                        { courseReviews.map(review => (
                                            <div className="review-box" key={review._id}>
                                                <h5 className="text-xs text-muted"><i>{review.user.firstName} {review.user.lastName} says...</i></h5>
                                                <p className="spaced text-xs bold-2 mb-0">{review.header}</p>
                                                <ul className="stars p-0 mb-2">
                                                    <li className="star text-md"><i className={`fa fa-star ${review.rating >= 1 ? "orange": ""}`}></i></li>
                                                    <li className="star text-md mx-1"><i className={`fa fa-star ${review.rating >= 2 ? "orange": ""}`}></i></li>
                                                    <li className="star text-md"><i className={`fa fa-star ${review.rating >= 3 ? "orange": ""}`}></i></li>
                                                    <li className="star text-md mx-1"><i className={`fa fa-star ${review.rating >= 4 ? "orange": ""}`}></i></li>
                                                    <li className="star text-md"><i className={`fa fa-star ${review.rating >= 5 ? "orange": ""}`}></i></li>
                                                </ul>
                                                <p className="spaced review-comment text-xs mb-0">{review.comment}</p>
                                            </div>
                                        ))}
                                    </div>
                                </Fragment>:<Fragment></Fragment>}


                                {/* FREE ACCESS CHECKER */}

                                <Fragment>
                                    {/* UNLOCKED VIDEOS */}
                                    {userCourses.includes(params.id) && sortedGrouped.length > 0 ? <h3 className="text-xs bold-2 mt-5">Enjoy the course videos below:</h3>
                                        : sortedGrouped.length > 0 ? <h3 className="text-xs bold-2 mt-5">What you will learn in this course:</h3>
                                        : <Fragment></Fragment>
                                    }
                                    {sortedGrouped.map((group, index) => (
                                        <Fragment key={index}>
                                            {Object.keys(group).map((date) => (
                                                <Fragment key={date}>
                                                    <div className="col-12 mb-4">
                                                        <h5 className="text-xs text-muted bold-2 my-4">SECTION {sortedGrouped.length - index}</h5>

                                                        <div className="wrapper">
                                                            {group[date].map((video) => (
                                                                <Fragment key={video.videoPosition}>
                                                                    <div className="wrapper-box">
                                                                    { (new Date() >= new Date(stayActiveDate) || video.unlocked) && userCourses.includes(params.id) ? 
                                                                        <video onContextMenu={handleContextMenu} className="course-vid bg-dark" controls poster={video.thumbnailURL}>
                                                                            <source src={video.videoURL} type={video.type} />
                                                                            Your browser does not support the video tag.
                                                                        </video>
                                                                    
                                                                        : <div className="course-vid" style={{backgroundImage: `url('${video.thumbnailURL}')`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                                                                    }
                                                                        <div className="px-2 pb-2 position-relative">
                                                                            <p className="p-ellipsis text-xs bold-2 mt-2 mb-0">{video.videoName.split('.')[0]}</p>
                                                                            <p className="text-xs mt-1 mb-0">{Math.ceil(video.videoDuration/60)} mins</p>
                                                                            {!userCourses.includes(params.id) ?
                                                                                <p className="text-xs mt-1 mb-2 bold-2 red">Enroll to play</p>
                                                                                : new Date() <= new Date(stayActiveDate) && !video.unlocked ? <p className="text-xs mt-1 mb-2 bold-2">Unlocked {course.schedule}</p>
                                                                                : <p className="text-xs mt-1 mb-2 bold-2 green">Active</p>
                                                                            }

                                                                            {new Date() <= new Date(stayActiveDate) && !video.unlocked ?
                                                                                <div className="lock" style={{backgroundImage: "url('/images/lock.png')", backgroundPosition: "center", backgroundSize: "cover"}}></div>
                                                                                : <Fragment></Fragment>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ))}
                                        </Fragment>
                                    ))}


                                    {/* LOCKED VIDEOS */}
                
                                    { new Date(stayActiveDate) == "Invalid Date" || (new Date() <= new Date(stayActiveDate) && lockedVids.length > 0) ?
                                        <div className="col-12 mb-5 mt-4">
                                            {userCourses.includes(params.id) ? <h3 className="text-xs bold-2 mt-4 mb-4">Anticipate:</h3>
                                            : <h3 className="text-xs bold-2 mt-4 mb-4">What you will learn in this course:</h3>}
                                            
                                            {/* VIDEOS */}
                                            <div className="wrapper">
                                                { course.name && lockedVids.map(video => (
                                                    <Fragment key={video.videoPosition}>
                                                        <div className="wrapper-box">

                                                            <div className="course-vid" style={{backgroundImage: `url('${video.thumbnailURL}')`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                                                            
                                                            <div className="px-2 pb-2 position-relative">
                                                                <p className="p-ellipsis text-xs bold-2 mt-2 mb-0">{video.videoName.split('.')[0]}</p>
                                                                <p className="text-xs mt-1 mb-0">{Math.ceil(video.videoDuration/60)} mins</p>
                                                                {!userCourses.includes(params.id) ?
                                                                    <p className="text-xs mt-1 mb-2 bold-2 red">Enroll to play</p>
                                                                    : <p className="text-xs mt-1 mb-2 bold-2">Unlocked {course.schedule}</p>
                                                                }

                                                                <div className="lock" style={{backgroundImage: "url('/images/lock.png')", backgroundPosition: "center", backgroundSize: "cover"}}></div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                ))}

                                            </div>
                                        </div>
                                    : <Fragment></Fragment>}
                                </Fragment>

                                {/* SINGLE REVIEW */}
                                {!userCourses.includes(params.id) ? <Fragment></Fragment> :
                                    <div className="col-12 mt-3 mb-4 mb-md-5">
                                        <h3 className="text-xs bold-2 mt-4 mb-3">Your review:</h3>
                                        <div className="py-4 px-3 bg-white border-orange">
                                        {review.rating ? 
                                            <Fragment>
                                                <p className="spaced text-xs bold-2 mb-0">{review.header}</p>
                                                <ul className="stars p-0">
                                                    <li className="star text-md"><i className={`fa fa-star ${review.rating >= 1 ? "orange" : ""}`}></i></li>
                                                    <li className="star text-md mx-1"><i className={`fa fa-star ${review.rating >= 2 ? "orange" : ""}`}></i></li>
                                                    <li className="star text-md"><i className={`fa fa-star ${review.rating >= 3 ? "orange" : ""}`}></i></li>
                                                    <li className="star text-md mx-1"><i className={`fa fa-star ${review.rating >= 4 ? "orange" : ""}`}></i></li>
                                                    <li className="star text-md"><i className={`fa fa-star ${review.rating >= 5 ? "orange" : ""}`}></i></li>
                                                </ul>
                                                <p className="spaced text-xs mt-2 mb-0">{review.comment}</p>
                                            </Fragment>
                                            : <p className="spaced text-xs mb-0">No review submitted</p>}
                                        </div>
                                    </div>
                                }

                            </div>
                    }
                </div>
            </Fragment>

        </Fragment>
    )
}
export default CourseDetails;