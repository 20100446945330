export const GET_RECIPES_REQUEST = 'GET_RECIPES_REQUEST'
export const GET_RECIPES_SUCCESS = 'GET_RECIPES_SUCCESS'
export const GET_RECIPES_FAIL = 'GET_RECIPES_FAIL'

export const NEW_RECIPE_REQUEST = 'NEW_RECIPE_REQUEST'
export const NEW_RECIPE_SUCCESS = 'NEW_RECIPE_SUCCESS'
export const NEW_RECIPE_RESET = 'NEW_RECIPE_RESET'
export const NEW_RECIPE_FAIL = 'NEW_RECIPE_FAIL'

export const UPDATE_RECIPE_REQUEST = 'UPDATE_RECIPE_REQUEST'
export const UPDATE_RECIPE_SUCCESS = 'UPDATE_RECIPE_SUCCESS'
export const UPDATE_RECIPE_FAIL = 'UPDATE_RECIPE_FAIL'
export const UPDATE_RECIPE_RESET = 'UPDATE_RECIPE_RESET'

export const DELETE_RECIPE_REQUEST = 'DELETE_RECIPE_REQUEST'
export const DELETE_RECIPE_SUCCESS = 'DELETE_RECIPE_SUCCESS'
export const DELETE_RECIPE_FAIL = 'DELETE_RECIPE_FAIL'
export const DELETE_RECIPE_RESET = 'DELETE_RECIPE_RESET'

export const RECIPE_DETAILS_REQUEST = 'RECIPE_DETAILS_REQUEST'
export const RECIPE_DETAILS_SUCCESS = 'RECIPE_DETAILS_SUCCESS'
export const RECIPE_DETAILS_RESET = 'RECIPE_DETAILS_RESET'
export const RECIPE_DETAILS_FAIL = 'RECIPE_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'