import React, { Fragment } from 'react'
import Metadata from './metadata'

const DeleteModal = ({title, item, onSelect}) => {

    const handleClickedButton = () => {
        onSelect("Delete")
    }

    return (
        <Fragment>
            <Metadata title={title}/>
            {/* Modal */}
            <div className="modal fade" id="delete-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-2 bold-2" id="delete-modal-label">Delete {item} record</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        <p className="text-2">Are you sure you want to delete this {item} record?</p>
                        <p className="text-2 bold-1 red">This action is irreversible</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary text-2" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" onClick={handleClickedButton} data-bs-dismiss="modal" className="btn btn-danger text-2">Delete</button>
                    </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
    }

export default DeleteModal
