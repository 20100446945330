import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { S3 } from 'aws-sdk';

import Metadata from '../layout/metadata'
import AdminNavigation from '../layout/adminNavigation'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { UPDATE_RECIPE_RESET } from '../../constants/recipeConstants'
import { updateRecipe, getRecipeDetails, clearErrors } from '../../actions/recipeActions'


const BUCKET = process.env.REACT_APP_AWS_BUCKET
const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;

const s3 = new S3({
    accessKeyId,
    secretAccessKey
});

let firstLoad = true;
let state = "Idle"
let uploadId = "";
let count = 0;
let previousLength = 0;
let recipeImage = ""
let recipeImageDetails = {}
let recipeVideo = ""
let recipeVideoDetails = {}

const categories = [
  "Plantain recipes",
  "Nigerian soups",
  "Chicken recipes",
  "Simple breakfast",
  "Pasta recipes",
  "OmoyeCooks special recipes"
  ]

const UpdateRecipe = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { error, loading, success, recipe: modifiedRecipe } = useSelector(state => state.modifyRecipe)
  const { recipe: recipeDetails } = useSelector(state => state.recipeDetails);

  let [recipe, setRecipe] = useState({
    name: '',
    category: '',
    description: '',
    ingredients: '',
    steps: '',
    image: {},
    video: {}
  });

  let { name, category, description, ingredients, steps } = recipe;

  if (recipeDetails._id && firstLoad) {
    firstLoad = false;
    setRecipe({
      name: recipeDetails.name,
      category: recipeDetails.category,
      description: recipeDetails.description,
      ingredients: recipeDetails.ingredients,
      steps: recipeDetails.steps
    })
  }

  const [isUpdate, setIsUpdate] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatusText, setUploadStatusText] = useState("");

  useEffect(() => {
    dispatch(getRecipeDetails(params.name, "Admin"))
    firstLoad = true;
    if (error) {
      if (error.includes("E11000")){
        toast.error("A recipe with this exact name already exists, please change the name.")
      } else {
        toast.error("An error occurred, please check your internet connection, refresh, and try again.")
      }
        dispatch(clearErrors())
    }
    if (success) {
      firstLoad = false;
      toast.success("Successfully updated Recipe 👍")
      params.name = modifiedRecipe.name
      dispatch({ type: UPDATE_RECIPE_RESET })
      navigate(`/admin/recipes/${modifiedRecipe.name}`)
    }
}, [dispatch, error, success]);

const startUpload = async () => {
  setUploadStatusText("Preparing to upload...")

  // UPLOAD IMAGE
  if (recipeImage) {
    const params = {
      Bucket: BUCKET,
      Key: `Recipes/${recipeDetails.key}/` + recipeImage.name,
      Body: recipeImage,
    };
    
    const uploadData = await s3.upload(params).promise();
    recipeImageDetails.URL = uploadData.Location
    recipe.image = recipeImageDetails
  } 
  // UPLOAD VIDEO
  if (recipeVideo) {
    const params = {
      Bucket: BUCKET,
      Key: `Recipes/${recipeDetails.key}/` + recipeVideo.name,
      ContentType: recipeVideo.type
    };

    s3.createMultipartUpload(params, (err, data) => {
      if (err) {
        console.log(err)
        toast.error("An error occurred, please check your internet connection, refresh, and try again.");
        state = "Idle";
        return dispatch(clearErrors());
      } else {
          uploadId = data.UploadId
          uploadParts();
      }
    });
  } else {
    setUploadStatusText("Upload completed successfully.")
    dispatch(updateRecipe(params.name, recipe))
  }
};

const uploadParts = async () => {

  // UPLOAD VIDEO
  const partSize = 5 * 1024 * 1024; // 5MB
  const totalParts = Math.ceil(recipeVideo.size / partSize);

  const parts = Array.from({ length: totalParts }, (_, i) => {
    const start = i * partSize;
    const end = Math.min((i + 1) * partSize, recipeVideo.size);

    return {
      partNumber: i + 1,
      body: recipeVideo.slice(start, end)
    };
  });

  setUploadStatusText("Uploading...")

  parts.forEach(({ partNumber, body }, index) => {

    const params = {
      Bucket: BUCKET,
      Key: `Recipes/${recipeDetails.key}/${recipeVideo.name}`,
      UploadId: uploadId,
      PartNumber: partNumber,
      Body: body
    };

    s3.uploadPart(params, (err, data) => {
      if (err) {
        console.log(err)
        toast.error("An error occurred, please check your internet connection, refresh, and try again.");
        dispatch(clearErrors());
        state = "Idle";
      } else {
        setUploadProgress(prevProgress => prevProgress + (100 / totalParts));
        count += 1
        if (count === totalParts) {
          completeUpload();
        }
      }
    }); 
  });
};

const completeUpload = async () => {
  setUploadStatusText("Stitching...")
  const listParams = {
    Bucket: BUCKET,
    Key: `Recipes/${recipeDetails.key}/${recipeVideo.name}`,
    UploadId: uploadId,
  };

  const { Parts } = await s3.listParts(listParams).promise();

  const partsToComplete = Parts.map(part => (({ ETag, PartNumber }) => ({ ETag, PartNumber }))(part));

  const completeParams = {
    Bucket: BUCKET,
    Key: `Recipes/${recipeDetails.key}/${recipeVideo.name}`,
    MultipartUpload: { Parts: partsToComplete},
    UploadId: uploadId
  }
  s3.completeMultipartUpload(completeParams, (err, data) => {
    if (err) {
      console.log(err)
      toast.error("An error occurred, please check your internet connection, refresh, and try again.");
      dispatch(clearErrors());
      state = "Idle";
    } else {
      count = 0;
      setUploadStatusText("Upload completed successfully.")
      recipeVideoDetails.URL = data.Location
      recipe.video = recipeVideoDetails;

      dispatch(updateRecipe(params.name, {isRemove: false, recipe: recipe}))
    }})

};

  const nameInputHandler = (event) => {
    const textRegex = /^[a-zA-Z0-9\s]+$/;
    if (textRegex.test(event.target.value)) {
      firstLoad = false;
      setRecipe({...recipe, [event.target.name]: event.target.value });
      setIsUpdate(true);
    }
  };

  const recipeOnChange = (event) => {
    firstLoad = false;
    setRecipe({...recipe, [event.target.name]: event.target.value });
    setIsUpdate(true);
  };

  const handleBulletInput = (event) => {
    const bullet = "\u2022";
    const newLength = event.target.value.length;
    const characterCode = event.target.value.substr(-1).charCodeAt(0);

    if (newLength > previousLength) {
      if (characterCode === 10) {
        event.target.value = `${event.target.value}${bullet} `;
      } else if (newLength === 1) {
        event.target.value = `${bullet} ${event.target.value}`;
      }
    }
    
    previousLength = 0;
    firstLoad = false;
    setRecipe({...recipe, [event.target.name]: event.target.value });
    setIsUpdate(true);
  }

  const onRecipeImageSelect= event => {
    const file = event.target.files[0]

    if (!file) {
      recipeImageDetails = {}
      recipeImage = ""
    }

    recipeImageDetails.name = file.name
    recipeImageDetails.type = file.type
    recipeImageDetails.size = (parseFloat(file.size) * 0.0000009537).toFixed(2) + "MB"
    
    recipeImage = file
    firstLoad = false;
    setIsUpdate(true);
  }

  const onRecipeVideoSelect= event => {
    const file = event.target.files[0]

    if (!file) {
      recipeVideoDetails = {}
      return recipeVideo = ""
    }

    recipeVideoDetails.name = file.name
    recipeVideoDetails.type = file.type
    recipeVideoDetails.size = (parseFloat(file.size) * 0.0000009537).toFixed(2) + "MB"
    recipeVideo = file
    firstLoad = false;
    setIsUpdate(true);
  }

  const onDone = () => {
    state = "Idle"
    navigate("/admin/recipes")
  }

  const removeVideo = () => {
    dispatch(updateRecipe(params.name, {isRemove: true}))
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    state = "Working"
    startUpload()
  }

  return (
    <Fragment>
      <Metadata title={'Update Recipe'} />
      <AdminNavigation Recipe/>
      <ToastContainer />
      <div className="container-fluid container-md bg-light shadow-lg p-0 mb-4">
        <div className="bg-dark border rounded p-3">
          <h3 className="text-md text-light m-0">Update Recipe</h3>
        </div>

        <div className="p-3">
          <Link to="/admin/recipes" className="text-dark text-xs">{"< Back to recipes"}</Link>
        </div>

        <form onSubmit={submitHandler} className="p-3 pb-5">
          <div className="row">

            {/* COURSE NAME */}
            <div className="col-md-6 mb-4">
            <label htmlFor="name" className="form-label">Recipe Name</label><i className="red">*</i>
                <input 
                    type="text" 
                    className="form-control" 
                    id="name"
                    name='name'
                    defaultValue={name} 
                    onChange={nameInputHandler}
                    aria-describedby="nameHelp" 
                    placeholder="E.g. Egusi Soup"
                    readOnly={state === "Working"}
                    required
                />
            </div>

            {/* CATEGORY */}
            <div className="col-md-6 mb-4">
                <label htmlFor="category" className="form-label">Category</label><i className="red-color">*</i>
                <select 
                    name="category" 
                    id="category" 
                    className="form-control"
                    value={category}
                    onChange={recipeOnChange}
                    disabled={state === "Working"}
                    required
                    >
                    <option value="">Choose category</option>
                    {categories.map(category => (
                        <option key={category} value={category}>{category}</option>
                    ))}
                </select>
            </div>

            {/* DESCRIPTION */}
            <div className="col-12 mb-4">
              <label htmlFor="description" className="form-label">Description</label><i className="red">*</i>
              <textarea 
                  className="form-control" 
                  id="description" 
                  name='description'
                  rows="2" 
                  defaultValue={description} 
                  onChange={recipeOnChange}
                  placeholder="Describe course..."
                  readOnly={state === "Working"}
                  required
              />
            </div>

            {/* INGREDIENTS */}
            <div className="col-md-6 mb-4">
              <label htmlFor="ingredients" className="form-label">Ingredients</label><i className="red">*</i>
              <textarea 
                  className="form-control" 
                  id="ingredients" 
                  name="ingredients"
                  rows="2" 
                  defaultValue={ingredients} 
                  onInput={handleBulletInput}
                  placeholder="Enter ingredients..."
                  readOnly={state === "Working"}
                  required
              />
            </div>  

            {/* REQUIREMENTS */}
            <div className="col-md-6 mb-4">
              <label htmlFor="steps" className="form-label">Cooking Steps</label><i className="red">*</i>
              <textarea 
                  className="form-control" 
                  id="steps" 
                  name="steps"
                  rows="2" 
                  defaultValue={steps} 
                  onInput={handleBulletInput}
                  placeholder="Enter cooking steps..."
                  readOnly={state === "Working"}
                  required
              />
            </div>

            {/* IMAGE */}
            <div className="col-md-6 mb-4">
              <label htmlFor="recipeImage" className="form-label">Select Image</label><i className="red">*</i>
              <p className="text-xs mb-2">Existing image - <span className="bold-2">"{recipeDetails.image && recipeDetails.image.name ? recipeDetails.image.name : "No image"}"</span></p>
              <input
                type="file"
                name="recipeImage"
                className="form-control"
                id="recipeImage"
                onChange={onRecipeImageSelect}
                accept="image/*"
                disabled={true}
                aria-describedby="recipeImageHelp"
              />
            </div>

            {/* VIDEO UPLOAD */}
            {/* <div className="col-md-6 video">
              <label htmlFor="video" className="form-label">Select Video</label> <i className="text-xs bold-1 red">(Cannot be less than 5MB)</i>
              <p className="text-xs mb-2">Existing video name - <span className="bold-2">"{recipeDetails.video && recipeDetails.video.name ? recipeDetails.video.name : "No video"}"</span>
                      {recipeDetails.video ? <button onClick={removeVideo} disabled={loading} className="action-btn py-1 px-2 ms-3">{loading ? "Removing..." :"Remove Video"}</button> : <Fragment></Fragment>}
              </p>
              <p className="red bold-1 text-xs">{recipeDetails.video && recipeDetails.video.name ? "Please rename new video to this exact name" : "Please use a short descriptive name"}</p>
              <input 
                  type="file" 
                  className="form-control" 
                  id="video"
                  name='video'
                  accept="video/*"
                  onChange={onRecipeVideoSelect}
                  disabled={state === "Working"}
                  aria-describedby="videoHelp" 
              />
            </div> */}

            {/* ###################################### */}

            {/* BUTTON */}
            {/* BUTTON */}
            <div className="d-flex flex-column align-items-end">
              { state === "Done" ?  
                <button type="button" onClick={onDone} className="upload-btn mt-4 mt-md-0">Done</button> :
              state === "Working" ? 
              <Fragment>
                <div className="d-block progress-bar mt-4 mt-md-0">
                  <div className="inner-bar" style={{width: `${uploadProgress}%`}}>
                    <strong className='text-warning'>{Math.round(uploadProgress)}%</strong>
                  </div>
                </div>
                <i className="d-block mt-2 text-xs">{uploadStatusText}</i>
              </Fragment>
              : <button className="upload-btn mt-4 mt-md-0"
                  type='submit'
                  disabled={!isUpdate || state === "Working"}
                >Update recipe</button>
              }
            </div>

          </div>

        </form>

      </div>
    </Fragment>
  );
};

export default UpdateRecipe;
