import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Loader from '../layout/loader'
import AdminNavigation from '../layout/adminNavigation'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getClassDetails, clearErrors } from '../../actions/classActions'

import { numberWithCommas } from '../../Functions/functions';

const ClassDetails = () => {

    const dispatch = useDispatch();
    const params = useParams();

    const { loading, error, singleClass } = useSelector(state => state.classDetails);

    useEffect(() => {
        dispatch(getClassDetails(params.id));

        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            console.log(error, "ERRORRR - academy.jsx")
            dispatch(clearErrors())
        }

    }, [dispatch, error, params.id])

    return (
        <Fragment>
            <Metadata title={"Class Details"}/>
            <AdminNavigation Classes />
            <ToastContainer/>
            <div className="container-fluid container-md">

                <Fragment>
                    <div className="d-flex align-items-center">
                        <h3 className="text-md text-muted d-inline-block m-0 bold-2">CLASS</h3>
                        <div className="d-flex ms-auto align-items-center">
                            <Link to="/admin/academy/add-course" className="add-btn ms-2">+ Add course</Link>
                            {/* <Link to="/admin/classes/add-course" className="add-btn ms-2">+</Link> */}
                        </div>
                    </div>
                
                </Fragment>                              
            </div>
        </Fragment>
    )
}

export default ClassDetails;