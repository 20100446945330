import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import AdminNavigation from '../layout/adminNavigation'

import { useDispatch, useSelector } from 'react-redux'
import { getAllUsers, clearErrors } from '../../actions/userActions'

let clicked = "All";
let firstLoad = true;

const AllUsers = () => {

    const dispatch = useDispatch();

    const { loading, error, users } = useSelector(state => state.allUsers );
    let [ filteredUsers, setFilteredUsers ] = useState(users)

    if (filteredUsers.length < 1 && firstLoad) {filteredUsers = users};

    useEffect(() => {
        dispatch(getAllUsers());
        if (error) {
            console.log(error, "ERRROORRRR!!")
            dispatch(clearErrors())
        }
    }, [dispatch, error])

    const rowData = [];

    const toggleTable = (role) => {
        firstLoad = false
        let filter
        clicked = role
        if (role === "All") {
            filter = users;
        } else if (role === "Verified") {
            filter = users.filter(user => user.userStatus === "Verified")
        } else if (role === "Unverified") {
            filter = users.filter(user => user.userStatus === "Unverified")
        } else if (role === "Suspended") {
            filter = users.filter(user => user.userStatus === "Suspended")
        } else {
            filter = []
        }

        setFilteredUsers(filter)
    }

    const onSearchChange = (e) => {
        if (e.target.value === "") {
            return setFilteredUsers(users)
        }
        let matches = users.filter(user => user.firstName.toLowerCase().includes(e.target.value.toLowerCase()) || user.lastName.toLowerCase().includes(e.target.value.toLowerCase()))

        setFilteredUsers(matches);
        firstLoad = false;
    }

    return (
        <Fragment>
            <Metadata title={"All Users"}/>
            <AdminNavigation Users/>
            <div className="container-fluid container-md mb-5">
                {/* {loading ? <Loader/> : ( */}
                <Fragment>
                    <div className="d-flex align-items-center mt-3">
                        <h3 className="text-xs text-muted d-inline-block m-0 me-4 bold-2">USERS</h3>
                        <div className="d-flex ms-auto align-items-center">
                            <input
                            className="form-control d-inline-block py-1" 
                            type="search" 
                            placeholder="Search users"
                            aria-label="Search"
                            onChange={onSearchChange}
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-column flex-md-row justify-content-between mt-3">
                        <span className="text-muted text-xs order-1 order-md-2">{filteredUsers.length} {clicked === 'All' ? 'Users' : clicked}</span>
                        <div className="table-nav-block order-2 order-md-1 mt-3 mt-md-0">
                            <button onClick={function() { toggleTable('All') }} className={`table-nav ms-0 ${clicked === 'All'?'table-active':''}`}>All</button>
                            <button onClick={function() { toggleTable('Verified') }} className={`table-nav ${clicked === 'Verified'?'table-active':''}`}>Verified</button>
                            <button onClick={function() { toggleTable('Unverified') }} className={`table-nav ${clicked === 'Unverified'?'table-active':''}`}>Unverified</button>
                            <button onClick={function() { toggleTable('Suspended') }} className={`table-nav ${clicked === 'Suspended'?'table-active':''}`}>Suspended</button>
                        </div>
                    </div>

                    {loading ?                        
                        <Fragment>
                            <div className="my-4">
                                <Skeleton count={15} height={20} baseColor="#dc720082"/>
                            </div>
                        </Fragment> :  (
                        <Fragment>
                            <table className="table mt-3">
                                <thead className="table-secondary py-5">
                                    <tr>
                                        <th scope="col">User</th>
                                        <th scope="col">Email</th>
                                        <th scope="col" className="text-center">Subscription</th>
                                        <th scope="col" className="text-center">Courses</th>
                                        <th scope="col" className="text-center">Date Created</th>
                                        <th scope="col" className="text-center">Action</th>
                                    </tr>
                                </thead>                            
                                {filteredUsers.forEach(user => rowData.push(
                                    <tr className="bg-white" key={user._id}>


                                        <td className="text-xs bold-2">{user.firstName} {user.lastName}</td>
                                        <td className="text-xs">{user.credential}</td>
                                        <td className="text-xs text-center">{user.subscription}</td>
                                        <td className="text-xs text-center">{user.courses.length}</td>
                                        <td className="text-xs text-center">{user.createdAt.split('T')[0]}</td>
                                        <td className="text-center"><Link to={`/admin/users/${user._id}`} state={{ from: "admin" }} className="orange bold-2">View</Link></td>
                                    </tr>                                 
                                ))}
                                <tbody>{rowData}</tbody>
                            </table>
                            { filteredUsers.length == 0 ? (
                                <div className="text-center py-3 w-100">
                                    <h3 className='text-sm'>NO DATA</h3>
                                </div>
                            ):(<Fragment></Fragment>)}
                        </Fragment>                          
                    )}
                </Fragment>
            </div>
        </Fragment>
    )
}

export default AllUsers;