import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import AdminNavigation from '../layout/adminNavigation'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { getProducts, clearErrors } from '../../actions/productActions'

import { CREATE_PRODUCT_RESET } from '../../constants/productConstants'

import { numberWithCommas } from '../../Functions/functions';

let clicked = "All";
let firstLoad = true;

const Products = () => {

    const dispatch = useDispatch();

    const { loading, error, products } = useSelector(state => state.getProducts);

    let [ filteredProducts, setFilteredProducts ] = useState(products)

    if (firstLoad) { filteredProducts = products };

    useEffect(() => {
        dispatch(getProducts("Admin"));
        dispatch({ type: CREATE_PRODUCT_RESET })

        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }

    }, [dispatch, error])


    const onSearchChange = (e) => {
        if (e.target.value === "") {
            return setFilteredProducts(products)
        }
        let matches = products.filter(products => products.name.toLowerCase().includes(e.target.value.toLowerCase()))

        setFilteredProducts(matches);
        firstLoad = false;
    }

    const toggleTable = (productlevel) => {
        firstLoad = false
        let filter
        clicked = productlevel
        if (productlevel === "All") {
            filter = products;
        } else if (productlevel === "Free") {
            filter = products.filter(products => products.price === 0)
        } else if (productlevel === "Paid") {
            filter = products.filter(products => products.price > 0)
        } else {
            filter = []
        }

        setFilteredProducts(filter)
    }

    const rowData = [];

    return (
        <Fragment>
            <Metadata title={"Products"}/>
            <AdminNavigation Products />
            <ToastContainer/>
            <div className="container-fluid container-md">
                <Fragment>
                    <div className="d-flex align-items-center mt-3">
                        <h3 className="text-2 text-muted d-inline-block m-0 me-4 bold-2">PRODUCTS</h3>
                        <div className="d-flex ms-auto align-items-center">
                            <input
                            className="form-control d-inline-block py-1" 
                            type="search" 
                            placeholder="Search products"
                            aria-label="Search"
                            onChange={onSearchChange}
                            />
                            <Link to="/admin/products/add-product" className="text-2 bold-2 d-none d-sm-block add-btn w-75 ms-2">+ Add product</Link>
                            <Link to="/admin/products/add-product" className="d-sm-none px-3 bold-3 add-btn ms-2">+</Link>
                        </div>
                    </div>

                    {/* FILTER */}
                    <div className="d-flex flex-column flex-md-row justify-content-between mt-3">
                        <span className="text-muted text-2 order-1 order-md-2">{filteredProducts.length} {clicked === 'All' ? 'products' : clicked + " products"}</span>
                        <div className="table-nav-block order-2 order-md-1 mt-3 mt-md-0">
                            <button onClick={function() { toggleTable('All') }} className={`table-nav ms-0 ${clicked === 'All'?'table-active':''}`}>All</button>
                            <button onClick={function() { toggleTable('Free') }} className={`table-nav ${clicked === 'Free'?'table-active':''}`}>Free</button>
                            <button onClick={function() { toggleTable('Paid') }} className={`table-nav ${clicked === 'Paid'?'table-active':''}`}>Paid</button>
                        </div>
                    </div>
                    {/* FILTER END */}

                    {loading ? 
                        <Fragment>
                            <div className="my-4">
                                <Skeleton count={15} height={20} baseColor="#dc720082"/>
                            </div>
                        </Fragment> : (
                        <Fragment>
                            <table className="table mt-3 mb-5">
                                <thead className="table-secondary py-5">
                                    <tr>
                                        <th scope="col"><i className="fa-solid fa-image"></i></th>
                                        <th scope="col">Product</th>
                                        <th scope="col">Price</th>
                                        <th scope="col" className="text-center">Category</th>
                                        <th scope="col" className="text-center">Stock</th>
                                        <th scope="col" className="text-center">Visibility</th>
                                        <th scope="col" className="text-center">Action</th>
                                    </tr>
                                </thead>

                                {filteredProducts.forEach(product => rowData.push(
                                    <tr className="bg-white" key={product._id}>
                                        <td><div style={{backgroundImage: `url('${product.images[0].url}')`, backgroundSize: 'cover'}} className="table-image border rounded"></div></td>
                                        <td className="text-2">{product.name}</td>
                                        <td className="text-2">&#x20A6;{numberWithCommas(product.price)}</td>
                                        <td className="text-2 text-center">{product.category}</td>
                                        <td className="text-2 text-center">{typeof product.stock === "number" ? product.stock : 1}</td>
                                        <td className="text-2 text-center">{product.hidden ? "Hidden" : "Visible"}</td>
                                        <td className="text-center text-2"><Link to={`/admin/products/${product._id}`} className="orange bold-2">View</Link></td>
                                    </tr>                                 
                                ))}
                                <tbody>{rowData}</tbody>
                            </table>
                            { filteredProducts.length === 0 ? (
                            <div className="text-center mb-5 w-100">
                                <h3 className='text-sm'>NO DATA</h3>
                            </div>
                            ):(<Fragment></Fragment>)}

                        </Fragment>
                    )}
                
                </Fragment>                              
                {/* )} */}
            </div>
        </Fragment>
    )
}

export default Products;