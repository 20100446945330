import React, { Fragment } from 'react'
import { verifyUser } from "../services/auth.service";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom'

import Metadata from '../layout/metadata'

const Welcome = () => {
    const params = useParams();

    verifyUser(params.confirmationCode)

    return (
        <Fragment>
            <Fragment>
                <Metadata title={'Verification'} />
                <div className="container-fluid container-md">
                    <div className="row shadow-lg mid-container">
                        <div className="col-md-8 py-5 p-md-5">
                        <h3 className="text-md mt-4 mb-3 bold-2">Account verified!</h3>

                        <p>Please <Link to={"/signin"} className="orange bold-2 mb-5">Sign in</Link></p>
                        </div>
                        <div className="col-md-4 d-none d-md-block text-end" style={{backgroundImage: "url('/images/signin-img.jpg')", backgroundSize: "cover", backgroundPosition: "center"}}>
                        </div>
                    </div>
                </div>    
            </Fragment>     
        </Fragment>
    )}

export default Welcome