import { 
        NEW_CLASS_REQUEST,
        NEW_CLASS_SUCCESS,
        NEW_CLASS_RESET,
        NEW_CLASS_FAIL,
        GET_CLASSES_REQUEST,
        GET_CLASSES_SUCCESS,
        GET_CLASSES_FAIL,
        CLASS_DETAILS_REQUEST,
        CLASS_DETAILS_SUCCESS,
        CLASS_DETAILS_FAIL,
        CLASS_DETAILS_RESET,

        CLEAR_ERRORS
    } from '../constants/classConstants'

export const getClassesReducer = (state = { classes:[] }, action) => {
    switch(action.type) {
        case GET_CLASSES_REQUEST:
            return {
                loading: true,
                classes: []
            }

        case GET_CLASSES_SUCCESS:
            return {
                loading: false,
                classes: action.payload
            }

        case GET_CLASSES_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const newClassReducer = (state = { newClass: {} }, action) => {
    switch(action.type) {
        case NEW_CLASS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case NEW_CLASS_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                newClass: action.payload.newClass

            }

        case NEW_CLASS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case NEW_CLASS_RESET:
            return {
                ...state,
                success: false,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const classDetailsReducer = (state = {singleClass: {}}, action) => {
    switch (action.type) {
        case CLASS_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CLASS_DETAILS_SUCCESS:
            return {
                loading: false,
                singleClass: action.payload
            }
        case CLASS_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLASS_DETAILS_RESET:
            return {
                loading: false,
                singleClass: {}
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}