import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Metadata from '../layout/metadata'

import { signin, clearErrors } from '../../actions/userActions'

import { initGA, logPageView } from '../../GoogleAnalytics';

const Signin = () => {
    let [credential, setCredential] = useState('')
    const [password, setPassword] = useState('')

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { isAuthenticated, error, loading } = useSelector(state => state.auth);
    const loggedInUser = useSelector(state => state.auth).user;

    useEffect(() => {
        initGA();
        logPageView();
        if (isAuthenticated) {
            const currState = {...location.state} || "" // FROM CART - Checks if user has shippable items in his cart and passes result to checkout
            const userRole = loggedInUser.role === "Admin" ? "/admin/dashboard" : "/"
            const redirect = location.search ? (`/${location.search.split('=')[1]}`) : userRole
            navigate(redirect, { state: currState  })
        }

        if (error) {
            toast.error(error)
            dispatch(clearErrors());
        }

    }, [dispatch, isAuthenticated, error])

    const submitHandler = (e) => {
        e.preventDefault();
        credential = credential.toLowerCase();
        credential = credential.trim();
        dispatch(signin(credential, password));
    }

    return (
        <Fragment>
            <Fragment>
                <Metadata title={'Signin'} />
                <ToastContainer />
                <div className="container-fluid container-md">
                    <div className="row shadow-lg mid-container">
                        <div className="col-md-8 p-md-4">
                            <form className="px-2" onSubmit={submitHandler}>
                                <div className="mb-3">
                                    <h3 className="text-lg bold-2 mb-5 mt-5">Sign In</h3>
                                    <label htmlFor="credential" className="form-label">Email address</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="credential" 
                                        aria-describedby="credentialHelp" 
                                        placeholder="E.g. john@email.com"
                                        value={credential}
                                        onChange={(e) => setCredential(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="Password" className="form-label">Password</label>
                                    <input 
                                        type="password" 
                                        className="form-control" 
                                        id="Password" 
                                        placeholder="**********"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="text-end"><Link to="/password/forgot" className="text-xs">Forgot Password?</Link></div>
                                <button 
                                    type="submit" 
                                    className="text-xs action-btn w-100 my-3"
                                    disabled={loading ? true : false}
                                    >{loading ? "Signing in..." : "Sign In"}</button>
                                <div className="form-label text-center text-xs mb-5">Don't have an account? <Link to="/register" className="text-end green-link">Create One</Link></div>
                            </form>
                        </div>
                        <div className="col-md-4 d-none d-md-block text-end" style={{backgroundImage: "url('/images/signin-img.jpg')", backgroundSize: "cover", backgroundPosition: "center"}}>
                        </div>
                    </div>
                </div>    
            </Fragment>     
        </Fragment>
    )}

export default Signin