import { 
    GRANT_ACCESS_REQUEST,
    GRANT_ACCESS_SUCCESS,
    GRANT_ACCESS_FAIL,
    GRANT_ACCESS_RESET,
    GET_ACCESS_LIST_REQUEST,
    GET_ACCESS_LIST_SUCCESS,
    GET_ACCESS_LIST_FAIL,
    CLEAR_ERRORS
} from '../constants/accessConstants'

export const grantAccessReducer = (state = { users: [] }, action) => {
    switch(action.type) {
        case GRANT_ACCESS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case GRANT_ACCESS_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                users: action.payload.users

            }

        case GRANT_ACCESS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case GRANT_ACCESS_RESET:
            return {
                ...state,
                success: false,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}

export const getAccessListReducer = (state = { accessList: [] }, action) => {
    switch(action.type) {
        case GET_ACCESS_LIST_REQUEST:
            return {
                loading: true,
                accessList: []
            }

        case GET_ACCESS_LIST_SUCCESS:
            return {
                loading: false,
                accessList: action.payload
            }

        case GET_ACCESS_LIST_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
}