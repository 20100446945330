import React, { Fragment } from 'react'

import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <Fragment>
        <footer className="container-fluid footer bg-black">
            <div className="container-md footer-content">
                <div className="row text-center">

                    <div className="col-6 col-md-3 mt-5 mt-md-0">
                        <div className="d-flex flex-column">
                            <h3 className="footer-header">Academy</h3>
                            <a href="/academy" className="footer-link">Free Courses</a>
                            <a href="/academy" className="footer-link">Paid Courses</a>
                            <a href="/academy" className="footer-link">All Courses</a>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 mt-5 mt-md-0">
                        <div className="d-flex flex-column">
                            <h3 className="footer-header">Recipes</h3>
                            <a href="/recipes" className="footer-link">Breakfast recipes</a>
                            <a href="/recipes" className="footer-link">Soup recipes</a>
                            <a href="/recipes" className="footer-link">Pasta recipes</a>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 mt-5 mt-md-0">
                        <div className="d-flex flex-column">
                            <h3 className="footer-header">Shop</h3>
                            <a href="/shop" className="footer-link">Spice Brands</a>
                            <Link to="/shop" className="footer-link">Cook Book</Link>
                            <Link to="/shop" className="footer-link">Customized T-shirts</Link>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 mt-5 mt-md-0">
                        <div className="d-flex flex-column">
                            <h3 className="footer-header">About</h3>
                            <a href="/about-omoye" className="footer-link">About Omoye</a>
                            <a href="#" className="footer-link">Privacy Policy</a>
                            <a href="#" className="footer-link">Support</a>
                        </div>
                    </div>
                </div>

                <div className="footer-seperator"></div>

                <div className="footer-bottom d-flex justify-content-between align-items-center flex-column flex-sm-row">
                    <div className="social-media-icons-block d-flex my-3 m-sm-0">
                        <a href="https://www.instagram.com/omoyecooks/?igshid=YmMyMTA2M2Y%3D" target="_blank"><img className="footer-icon" src="/images/instagram-icon.png" alt="Instagram Icon" /></a>
                        <a href="https://youtube.com/@omoyecooks" target="_blank"><img className="ms-2 footer-icon" src="/images/youtube-icon.png" alt="YouTube Icon" /></a>
                        {/* <Link to="" ><img className="mx-3" src="/images/facebook-icon.png" alt="Facebook Icon" /></Link> */}
                    </div>
                    <div className="copyright-block">
                        <span className="copyright-text">© 2023 OmoyeCooks • All Rights Reserved</span>
                    </div>
                </div>
            </div>
        </footer>
    </Fragment>
  )
}

export default Footer