import ReactGA from "react-ga4";



export const initGA = () => {
    ReactGA.initialize("G-PE44YMG8RH");
};

export const logPageView = () => {
    // console.log(window.location.pathname)
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};
