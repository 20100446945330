export const CREATE_REVIEW_REQUEST = 'CREATE_REVIEW_REQUEST'
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS'
export const CREATE_REVIEW_RESET = 'CREATE_REVIEW_RESET'
export const CREATE_REVIEW_FAIL = 'CREATE_REVIEW_FAIL'

export const GET_REVIEWS_REQUEST = 'GET_REVIEWS_REQUEST'
export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS'
export const GET_REVIEWS_FAIL = 'GET_REVIEWS_FAIL'

export const REVIEW_DETAILS_REQUEST = 'REVIEW_DETAILS_REQUEST'
export const REVIEW_DETAILS_SUCCESS = 'REVIEW_DETAILS_SUCCESS'
export const REVIEW_DETAILS_RESET = 'REVIEW_DETAILS_RESET'
export const REVIEW_DETAILS_FAIL = 'REVIEW_DETAILS_FAIL'

export const UPDATE_REVIEW_REQUEST = 'UPDATE_REVIEW_REQUEST'
export const UPDATE_REVIEW_SUCCESS = 'UPDATE_REVIEW_SUCCESS'
export const UPDATE_REVIEW_FAIL = 'UPDATE_REVIEW_FAIL'
export const UPDATE_REVIEW_RESET = 'UPDATE_REVIEW_RESET'

export const DELETE_REVIEW_REQUEST = 'DELETE_REVIEW_REQUEST'
export const DELETE_REVIEW_SUCCESS = 'DELETE_REVIEW_SUCCESS'
export const DELETE_REVIEW_FAIL = 'DELETE_REVIEW_FAIL'
export const DELETE_REVIEW_RESET = 'DELETE_REVIEW_RESET'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'