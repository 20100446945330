import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Metadata from '../layout/metadata'
import AdminNavigation from '../layout/adminNavigation'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { getCourseDetails, clearErrors } from '../../actions/courseActions'

import { PreviewDropzone, CourseImagesDropzone, CourseVideosDropzone } from './dropzone';
import UploadFile from './uploadCourse'

const AddVideos = () => {
  let preview = useRef([])
  let courseImages = useRef([])
  let courseVideos = useRef([])
  let previewFiles = useRef({})
  let courseFiles = useRef([])
  let allFiles = useRef([])
  let toStartFrom = useRef(0);

  const dispatch = useDispatch();
  const params = useParams();

  const [uploadStatus, setUploadStatus] = useState("Idle");

  const [showUploadFile, setShowUploadFile] = useState(false);
  const uploadFileRef = useRef(null);

  const { error, loading, course } = useSelector(state => state.courseDetails);

  useEffect(() => {
    dispatch(getCourseDetails(params.id, "Admin"));

    if (showUploadFile && uploadFileRef.current) {
      uploadFileRef.current.startUpload();
    }

    if (error) {
        toast.error("An error occurred, please check your internet connection, refresh, and try again.")
        dispatch(clearErrors())
    }
}, [dispatch, error, showUploadFile, uploadFileRef])

function handleChange(change, value) {
  if (change === "Preview") {
    preview.current = value;
    previewFiles.current.image = preview.current[0]
    previewFiles.current.video = preview.current[1]
  } else if (change === "Course Images") {
    courseImages.current = value;
  } else if (change === "Course Videos") {
    courseVideos.current = value;
  }

  courseFiles.current = courseVideos.current.map((item, index) => ({
    image: courseImages.current[index],
    video: item
  }));

  if (!previewFiles.current.image && !previewFiles.current.video) {
    toStartFrom.current = 1;
    return allFiles.current = [...courseFiles.current]
  }
  allFiles.current = [previewFiles.current, ...courseFiles.current]
  toStartFrom.current = 0;
}

function getStatus(uploadStatus) {
  setUploadStatus(uploadStatus)
}

function handleButtonClick() {
  if (course.prevVideo && course.prevVideo.videoURL && course.courseVideos.length === 0) {
    toStartFrom.current = 1
  }
  else if (course.courseVideos.length > 0) {
    toStartFrom.current = course.courseVideos.reduce((max, obj) => {
      return obj.videoPosition > max ? obj.videoPosition : max;
    }, 0);
    toStartFrom.current += 1;
  }

  setShowUploadFile(true);
}

  return (
    <Fragment>
      <Metadata title={'Add Course'} />
      <AdminNavigation Academy/>
      <ToastContainer />
      <div className="container-fluid container-md bg-light shadow-lg p-0 pb-5 mb-4">
          <Fragment>
            <div className="bg-dark border rounded p-3">
              <h3 className="text-sm text-light m-0">Add Videos to {course.name}</h3>
            </div>

            <div className="p-3">
            <Link to={`/admin/academy/${params.id}`} className="text-dark text-xs">{"< Back to Course"}</Link>
            </div>

            {!course.prevVideo ? 
            <div className="container-fluid container-md my-4">
              <p className="text-xs bold-2">Preview Image and Video</p>
              <PreviewDropzone initialImage={null} initialVideo={null} onChange={handleChange}/>
            </div>  : <Fragment></Fragment>
            }

            <div className="container-fluid container-md mt-4">
              <p className="text-xs bold-2">Course Images</p>
              <CourseImagesDropzone initialValue={courseImages.current} onChange={handleChange}/>
            </div>

            <div className="container-fluid container-md mt-5">
                <p className="text-xs bold-2">Course Videos</p>
                <CourseVideosDropzone initialValue={courseVideos.current} onChange={handleChange}/>
            </div>

            <div className="container-fluid container-md mt-4 mb-4">
                {/* Conditional rendering to show the UploadFile component */}
                {showUploadFile && (
                <UploadFile id={params.id} filesToUpload={allFiles.current} courseKey={course.key} startFrom={toStartFrom.current} ref={uploadFileRef} sendStatus={getStatus}/>
                )}
            </div>

            <div className="container-fluid container-md">
              {uploadStatus === "Complete" ? <Link to="/admin/academy" className="text-xs upload-btn w-100">Done</Link>
              : uploadStatus === "Uploading" ? <button disabled className="text-xs upload-btn w-100">Uploading Files...</button>
              : <button onClick={handleButtonClick} className="text-xs upload-btn w-100" disabled={allFiles.current[0]}>Upload Files</button> }
              
            </div>
          </Fragment>
      </div>
    </Fragment>
  );
};

export default AddVideos;
