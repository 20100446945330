import React, { Fragment, useState, useRef } from 'react'

const CourseAccessListModal = ({accessList}) => {

    const firstLoad = useRef(true)

    let [ filteredList, setFilteredList ] = useState(accessList)

    if (firstLoad.current) { filteredList = accessList };

    const onSearchChange = (e) => {
        if (e.target.value === "") {
            return setFilteredList(accessList)
        }
        let matches = accessList.filter(user => user.toLowerCase().includes(e.target.value.toLowerCase()))

        setFilteredList(matches);
        firstLoad.current = false;
    }

    return (
        <Fragment>
            <div className="modal fade" id="accessListModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="accessListModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content text-dark">
                        <div className="modal-header">
                            <span className="modal-title text-2 bold-2" id="accessListModalLabel">Students with free access</span>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body text-center">
                            <p className="text-2 mt-3">These users can enroll for free.</p>

                            <input
                                className="form-control d-inline-block py-1 w-50 mt-3 mb-4" 
                                type="search" 
                                placeholder="Search users"
                                aria-label="Search"
                                onChange={onSearchChange}
                            />
                            <div className="border rounded py-3">
                                {filteredList.map(user => 
                                    <p key={user} className="text-2 m-1">{user}</p>    
                                )}
                                {filteredList.length === 0 ? <p className="text-2 m-0">No record</p> : <Fragment></Fragment>}
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary text-xs" data-bs-dismiss="modal">Close</button>
                        </div>
                    
                    </div>
                </div>
            </div>
        </Fragment>
        )
    }

export default CourseAccessListModal;
