import axios from 'axios';
import {
    NEW_COURSE_REQUEST,
    NEW_COURSE_SUCCESS,
    NEW_COURSE_FAIL,
    UPDATE_COURSE_REQUEST,
    UPDATE_COURSE_SUCCESS,
    UPDATE_COURSE_FAIL,
    ENROLL_COURSE_REQUEST,
    ENROLL_COURSE_SUCCESS,
    ENROLL_COURSE_FAIL,
    GET_COURSES_REQUEST,
    GET_COURSES_SUCCESS,
    GET_COURSES_FAIL,
    COURSE_DETAILS_REQUEST,
    COURSE_DETAILS_SUCCESS,
    COURSE_DETAILS_FAIL,
    CLEAR_ERRORS
} from '../constants/courseConstants'

export const newCourse = (courseData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_COURSE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/v1/admin/academy/new', courseData, config)

        dispatch({
            type: NEW_COURSE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: NEW_COURSE_FAIL,
            payload: error.response.data.errMessage
        })
    }
};

export const modifyVideos = (id, formData) => async (dispatch) => {

    try {
        dispatch({ type: UPDATE_COURSE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/academy/${id}/add-videos`, formData, config)

        dispatch({
            type: UPDATE_COURSE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: UPDATE_COURSE_FAIL,
            payload: error.response.data.errMessage
        })
    }
};

export const updateCourse = (id, courseData) => async (dispatch) => {

    try {
        dispatch({ type: UPDATE_COURSE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/academy/${id}/update`, courseData, config)

        dispatch({
            type: UPDATE_COURSE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: UPDATE_COURSE_FAIL,
            payload: error.response.data.errMessage
        })
    }
};

export const enrollCourse = (id, courseData) => async (dispatch) => {

    try {
        dispatch({ type: ENROLL_COURSE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/academy/${id}/enroll`, courseData, config)

        dispatch({
            type: ENROLL_COURSE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ENROLL_COURSE_FAIL,
            payload: error.response.data.errMessage
        })
    }
};

export const getCourses = (role) => async (dispatch) => {
    let URL = '/api/v1/admin/academy';
    if (role !== "Admin") { URL = '/api/v1/academy' }

    try {
        dispatch({ type: GET_COURSES_REQUEST })

        const { data } = await axios.get(URL)

        dispatch({
            type: GET_COURSES_SUCCESS,
            payload: data.courses
        })
    } catch (error) {
        dispatch({
            type: GET_COURSES_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getCourseDetails = (id, role) => async (dispatch) => {
    let URL =  `/api/v1/academy/${id}`;
    if (role === "Admin") { URL =  `/api/v1/admin/academy/${id}` }

    try {
        dispatch({ type: COURSE_DETAILS_REQUEST })
        const { data } = await axios.get(URL)

        dispatch({
            type: COURSE_DETAILS_SUCCESS,
            payload: data.course
        })
    } catch (error) {
        dispatch({
            type: COURSE_DETAILS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const modifyCourse = (id, courseData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_COURSE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/admin/academy/${id}`, courseData, config)

        dispatch({
            type: UPDATE_COURSE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: UPDATE_COURSE_FAIL,
            payload: error.response.data.errMessage
        })
    }
};

export const setViews = (id, type) => async (dispatch) => {

    try {
        dispatch({ type: UPDATE_COURSE_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        const { data } = await axios.put(`/api/v1/academy/${id}`, {type: type}, config)

        dispatch({
            type: UPDATE_COURSE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: UPDATE_COURSE_FAIL,
            payload: error.response.data.errMessage
        })
    }
};

// CLEAR ERRORS
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}