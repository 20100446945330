import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Metadata from '../layout/metadata'

const OrderSuccess = () => {
  return (
    <Fragment>
        <Metadata title={'Order Success'}/>

        <div className="container-md my-5">
            <div className="d-flex flex-column align-items-center justify-content-center py-5">
                <img src="/images/success.png" alt="Order Success Checkmark" className="mb-5" width="100" height="100" />
                <h4 className="text-center text-3">Your order has been placed successfully.</h4>
                <h4 className="text-center text-2 spaced mt-3">If your order includes an e-book, you can already download it in "My Ebooks"</h4>
                <div className="d-flex">
                  <Link to={"/shop/my-orders"} className="text-2 orange mt-3 bold-2">Go to orders</Link>
                  <span className="text-2 orange mt-3 mx-3 bold-2">|</span>
                  <Link to={"/shop/my-ebooks"} className="text-2 orange mt-3 bold-2">Go to ebooks</Link>
                </div>
            </div>
           
        </div>
    </Fragment>
  )
}

export default OrderSuccess