import axios from 'axios';
import {
    CREATE_SHIPPING_ADDRESS_REQUEST,
    CREATE_SHIPPING_ADDRESS_SUCCESS,
    CREATE_SHIPPING_ADDRESS_FAIL,
    GET_SHIPPING_ADDRESSES_REQUEST,
    GET_SHIPPING_ADDRESSES_SUCCESS,
    GET_SHIPPING_ADDRESSES_FAIL,
    SHIPPING_ADDRESS_DETAILS_REQUEST,
    SHIPPING_ADDRESS_DETAILS_SUCCESS,
    SHIPPING_ADDRESS_DETAILS_FAIL,
    UPDATE_SHIPPING_ADDRESS_REQUEST,
    UPDATE_SHIPPING_ADDRESS_SUCCESS,
    UPDATE_SHIPPING_ADDRESS_FAIL,
    DELETE_SHIPPING_ADDRESS_REQUEST,
    DELETE_SHIPPING_ADDRESS_SUCCESS,
    DELETE_SHIPPING_ADDRESS_FAIL,
    CLEAR_ERRORS
} from '../constants/shippingAddressConstants'

export const newShippingAddress = (shippingAddressData) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_SHIPPING_ADDRESS_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/v1/shop/shipping_address/new', shippingAddressData, config)

        dispatch({
            type: CREATE_SHIPPING_ADDRESS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: CREATE_SHIPPING_ADDRESS_FAIL,
            payload: error.response.data.errMessage
        })
    }
};

export const getShippingAddresses = () => async (dispatch) => {

    try {
        dispatch({ type: GET_SHIPPING_ADDRESSES_REQUEST })

        const { data } = await axios.get('/api/v1/shop/shipping_address')

        dispatch({
            type: GET_SHIPPING_ADDRESSES_SUCCESS,
            payload: data.shippingAddresses
        })
    } catch (error) {
        dispatch({
            type: GET_SHIPPING_ADDRESSES_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const getShippingAddressDetails = (id, role) => async (dispatch) => {
    let URL =  `/api/v1/shipping_address/${id}`;
    if (role === "Admin") { URL =  `/api/v1/shop/shipping_address/${id}` }

    try {
        dispatch({ type: SHIPPING_ADDRESS_DETAILS_REQUEST })
        const { data } = await axios.get(URL)

        dispatch({
            type: SHIPPING_ADDRESS_DETAILS_SUCCESS,
            payload: data.shippingAddress
        })
    } catch (error) {
        dispatch({
            type: SHIPPING_ADDRESS_DETAILS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const updateShippingAddress = (id, update) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_SHIPPING_ADDRESS_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.put(`/api/v1/shop/shipping_address/${id}`, update, config)

        dispatch({
            type: UPDATE_SHIPPING_ADDRESS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: UPDATE_SHIPPING_ADDRESS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

export const deleteShippingAddress = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_SHIPPING_ADDRESS_REQUEST })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const { data } = await axios.delete(`/api/v1/shop/shipping_address/${id}`, config)

        dispatch({
            type: DELETE_SHIPPING_ADDRESS_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: DELETE_SHIPPING_ADDRESS_FAIL,
            payload: error.response.data.errMessage
        })
    }
}

// CLEAR ERRORS
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}