import React, { Fragment, useState, useEffect } from 'react'

import Metadata from '../layout/metadata';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import { modifyCourse, clearErrors } from '../../actions/courseActions'
import { UPDATE_COURSE_RESET } from '../../constants/courseConstants'

let isEndsession = false;
const EndSessionModal = ( { course } ) => {

    const dispatch = useDispatch();

    const params = useParams();
    const navigate = useNavigate();

    let { loading, error, success } = useSelector(state => state.newCourse)

    useEffect(() => {
        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }

        if (success) {
            dispatch({ type: UPDATE_COURSE_RESET })
            if (isEndsession) {
                navigate(`/admin/academy/${course._id}/update`)
                toast.info("Update start and end date for new session.")
            }
            isEndsession = false;
        }

    }, [dispatch, success, error, params.id])

    const endSession = (e) => {
        isEndsession = true;
        dispatch(modifyCourse( params.id, { endSession: true } ));
    }

    return (
        <Fragment>
            <Metadata title={'Course Details'} />
            <ToastContainer />

                <div className="modal fade" id="endSessionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content text-dark">
                            <div className="modal-header">
                                <span className="modal-title text-sm bold-2" id="endSessionModalLabel">End Session {course.session} for <i className="bold-1">{course.name}</i></span>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>

                            <div className="modal-body text-center">
                                <p className="text-sm mt-3 bold-2">Are you sure you want to end session {course.session}?</p>
                                <p className="text-sm bold-2 red">This action is irreversible and will lock all videos for the start of the next session.</p>
                                <p className="text-sm bold-2">All enrollees of this session will retain their access.</p>
                                <p className="text-sm bold-2 red">Remember to update start and end date for new session.</p>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary text-xs" data-bs-dismiss="modal">Close</button>
                                <button onClick={endSession} type="button" className="btn btn-danger text-xs" data-bs-dismiss="modal">Yes, end session</button>
                            </div>
                        
                        </div>
                    </div>
                </div>

        </Fragment>
    )
    }

export default EndSessionModal;
