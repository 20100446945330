import React, { Fragment, useState } from 'react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const CourseAccessModal = ({onSubmit}) => {

    const [users, setUsers] = useState("")

    const handleBulletInput = (event) => {
        const bullet = "\u2022";
        const newLength = event.target.value.length;
        const characterCode = event.target.value.substr(-1).charCodeAt(0);
    
        if (newLength > 0) {
          if (characterCode === 10) {
            event.target.value = `${event.target.value}${bullet} `;
          } else if (newLength === 1) {
            event.target.value = `${bullet} ${event.target.value}`;
          }
        }
    
        setUsers(event.target.value);
      }

    const submitHandler = () => {
        if (!users) toast.error("You haven't added any users!")
        const treatedUsers = users.replace(/• /g, '').split("\n").map(email => email.trim().toLowerCase());
        onSubmit(treatedUsers)
    }

    return (
        <Fragment>
            <ToastContainer />
            <div className="modal fade" id="accessModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="accessModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content text-dark">
                        <div className="modal-header">
                            <span className="modal-title text-2 bold-2" id="accessModalLabel">Grant free access</span>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body text-center">
                            <p className="text-2 mt-3">The users you add here will be able to enroll in this course for free.</p>
                            <p className="text-2 red bold-2">This action is irreversible.</p>

                            {/* USERS */}
                            <form className="mb-4">
                                <textarea 
                                    className="form-control" 
                                    id="users" 
                                    name="users"
                                    rows="5" 
                                    value={users}
                                    onInput={handleBulletInput}
                                    placeholder="Enter list of users to grant free access to this course..."
                                    required
                                />
                            </form> 
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary text-xs" data-bs-dismiss="modal">Close</button>
                            <button type="button" onClick={submitHandler} className="btn btn-success text-xs" data-bs-dismiss="modal">Grant access</button>
                        </div>
                    
                    </div>
                </div>
            </div>
        </Fragment>
        )
    }

export default CourseAccessModal;
