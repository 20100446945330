import React, { Fragment, useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import Metadata from '../layout/metadata'
import CheckoutSteps from './checkoutSteps'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { useDispatch, useSelector } from 'react-redux'

import { getShippingAddresses, clearErrors } from '../../actions/shippingAddress'

const Shipping = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const currState = location.state;

    const { shippingAddresses, loading, error } = useSelector(state => state.getShippingAddresses)

    const [selected, setSelected] = useState(0)

    useEffect(() => {
        dispatch(getShippingAddresses())
        if (error) {
            toast.error("Error getting shipping addressed.")
            dispatch(clearErrors())
        }
    }, [dispatch, error])
    
    const selectedAddress = (index) => {
        setSelected(index)
    }


    const onClickConfirm = () => {
        navigate("/shop/checkout/confirm-order", { state: {...location.state, shippingInfo: shippingAddresses[selected]}})
    }


    return (
    <Fragment>
        <Metadata title={'Checkout - Shipping'}/>
        <ToastContainer />
        <div className="container-md container-fluid pt-4">
            <CheckoutSteps shipping currState={{...location.state}}/>
            
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3 className="text-2 text-muted bold-2 m-0">Choose Shipping Address</h3>
                {shippingAddresses && shippingAddresses.length <= 3 ?
                <Link to="/shop/checkout/shipping/add-address" state={currState} className="orange text-2 bold-2 m-0">+ Add address</Link> : <Fragment></Fragment>}
            </div>

            <hr className="light-hr"/>

            {loading ?
                <div className="my-3">
                    <Skeleton count={1} height={140} baseColor="#dc720082"/>
                </div>
                :
                <Fragment>
                    <div className="row g-5 m-0 mb-5">
                        {shippingAddresses && shippingAddresses.map((address, index) => (
                            <div key={index} className="col-md-6 mt-3 px-1">
                                <div className={`click border-orange rounded p-4 h-100 ${selected === index ? "bg-orange white" : ""}`} alt={index} onClick={function() { selectedAddress(index) }}>
                                    <p className="text-2 bold-1 mb-2">{address.street}</p>
                                    <p className="text-1 bold-1 mb-2">{address.city}, {address.state}</p>
                                    <p className="text-1 bold-1 mb-0">{address.country}</p>
                                </div>
                            </div>
                        ))}
                    </div>

                    {shippingAddresses && shippingAddresses.length === 0 ?
                        <div className="text-center">
                            <p className="text-2 bold-2 mb-5">Please add a shipping address to continue</p>
                            <Link to="/shop/checkout/shipping/add-address" state={currState} className="w-auto orange-btn text-2 mb-5 py-2 px-3">Add shipping address</Link>  
                        </div>
                    : <Fragment></Fragment>}
                </Fragment>
            }


            <div className="d-flex justify-content-end my-5">
                <Link to="/shop/cart" className="d-flex align-items-center justify-content-center text-2 orange bold-1">Back to Cart</Link>
                {shippingAddresses && shippingAddresses.length === 0 ? <Fragment></Fragment> :
                <button onClick={onClickConfirm} className="d-flex align-items-center justify-content-center orange-btn text-2 ms-3" >Confirm order</button>}
            </div>
        </div>
    </Fragment>
    )
}

export default Shipping