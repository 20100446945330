import React, { Fragment, useEffect } from 'react'

import ShopCarousel from '../layout/shopCarousel'

import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { initGA, logPageView } from '../../GoogleAnalytics';
import { getProducts, clearErrors as productClearErrors } from '../../actions/productActions'
import { getPromos, clearErrors } from '../../actions/promoActions'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Metadata from '../layout/metadata';
import ProductCard from './productCard';


const ShopHome = () => {
    const dispatch = useDispatch();

    const { loading, error, products } = useSelector(state => state.getProducts);
    const { error: promosError, promos } = useSelector(state => state.getPromos);

    const ebooks = products.filter(product => product.category === "Ebooks")
    const cookbooks = products.filter(product => product.category === "Cookbooks")
    const spices = products.filter(product => product.category === "Spices")
    const utensils = products.filter(product => product.category === "Utensils")
    const Tshirts = products.filter(product => product.category === "Tshirts")
    const services = products.filter(product => product.category === "Services")

    const activePromotions = promos.filter(promo => new Date() >= new Date(promo.startDate) && new Date() <= new Date(promo.endDate))

    useEffect(() => {
        initGA();
        logPageView();

        dispatch(getPromos());
        dispatch(getProducts());

        if (promosError) {
            toast.error("Error fetching promos.")
            dispatch(clearErrors())
        }
        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(productClearErrors())
        }

    }, [dispatch, promosError, error]);

  return (
    <Fragment>
        <Metadata title={'Shop'} />
        <ToastContainer />
        <ShopCarousel images={["shop1.png", "shop3.jpg"]}/>
        <div className="container-fluid container-md">

            {/* TOP CAT */}
            <div className="shop-section">
                <h5 className="bold-2 text-3">Shop Our Categories</h5>
                <div className="underline"></div>
                <div className="wrapper">
                    <Link to="ebooks" state={{ebooks}} className="category-box">
                        <h5 className="cat-text text-2 text-white bold-2">Ebooks</h5>
                        <img src="https://png.pngtree.com/thumb_back/fh260/background/20221108/pngtree-in-the-bookstore-a-vibrant-pile-of-books-and-an-electronic-reading-device-photo-image_4388420.jpg" alt="" className="category-image" />
                    </Link>
                    <Link to="spices" state={{spices}} className="category-box">
                        <h5 className="cat-text text-2 text-white bold-2">Spices</h5>
                        <img src="https://www.24mantra.com/wp-content/uploads/2020/11/824x465-12.png" alt="" className="category-image" />
                    </Link>
                    <Link to="utensils" state={{utensils}} className="category-box">
                        <h5 className="cat-text text-2 text-white bold-2">Utensils</h5>
                        <img src="https://media.istockphoto.com/id/1307632435/photo/open-drawer-with-different-utensils-in-kitchen-top-view.jpg?s=612x612&w=0&k=20&c=kRUGQXwtqEE-7NColZsfJd___iQc-B2OZWbnZcTdhjg=" alt="" className="category-image" />
                    </Link>
                    <Link to="Tshirts" state={{Tshirts}} className="category-box">
                        <h5 className="cat-text text-2 text-white bold-2">T-shirts</h5>
                        <img src="https://www.rushordertees.com/_next/image/?url=https%3A%2F%2Fimages.prismic.io%2Frushordertees-web%2F6c6831f7-2965-4dc9-8770-b0ad97c8279d_Short%2BSleeve%2BT-Shirts.jpg%3Fauto%3Dcompress%2Cformat%26rect%3D0%2C0%2C1600%2C1800%26w%3D800%26h%3D900&w=1920&q=75" alt="" className="category-image" />
                    </Link>
                    <Link to="cookbooks" state={{cookbooks}} className="category-box">
                        <h5 className="cat-text text-2 text-white bold-2">Cookbooks</h5>
                        <img src="https://cdn.loveandlemons.com/wp-content/uploads/2023/01/cookbook3.jpg" alt="" className="category-image" />
                    </Link>
                    <Link to="services" state={{services}} className="category-box">
                        <h5 className="cat-text text-2 text-white bold-2">Services</h5>
                        <img src="https://post.healthline.com/wp-content/uploads/2021/09/1488883-The-6-Best-Mediterranean-Diet-Meal-Delivery-Services-of-2021-732x549-Feature-732x549.jpg" alt="" className="category-image" />
                    </Link>
                    
                </div>
            </div>

            {/* TOP PRODS */}
            <div className="shop-section">
                <h5 className="bold-2 text-3">Peek our top products</h5>
                <div className="underline"></div>

                    {loading ? 
                        <Fragment>
                            <div className="my-4">
                                <Skeleton count={1} height={300} baseColor="#dc720082"/>
                            </div>
                        </Fragment>:
                        <Fragment>
                            <div className="wrapper">
                                {products && products.filter(product => !product.hidden).map(product => (
                                    <ProductCard key={product._id} product={product} activePromotions={activePromotions} className={"wrapper-box"}/>
                                ))}
                            </div>
                        </Fragment>
                    }
            </div>

            {/* On Sale */}
            {/* <div className="shop-section">
                <h5 className="bold-2 text-3">Hurry! These are on sale</h5>
                <div className="underline"></div>
                <div className="wrapper">
                    <Link to={`/`}  key={"key"}  className="wrapper-box shadow-sm">
                        <div className="course-vid bg-white" style={{backgroundImage: `url('https://images.yaoota.com/b3rRVzPQDdTJF6dMSjtKIN8iDSY=/trim/yaootaweb-production-ng/media/crawledproductimages/1ce1de3c704fdb47f15002d1f1d0c3701e495507.jpg')`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                        <div className="text-start px-2 pb-4">
                            <p className="p-ellipsis text-2 mt-3 mb-0">Finely coated wonderful pots</p>
                            <p className="text-3 bold-2 my-2">&#x20A6;{numberWithCommas(3406)} <span className="bold-1 text-muted text-1 text-decoration-line-through">&#x20A6;{numberWithCommas(2207)}</span></p>
                            <p className="bold-2 text-1 mb-0 orange"><i className="fa-solid fa-plus"></i> Add to Cart</p>
                        </div>
                    </Link>
                </div>
            </div> */}



        </div>             
    </Fragment>
  )
}

export default ShopHome