import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useNavigate } from 'react-router-dom';

// import { getProducts } from '../../actions/productActions';
// import { numberWithCommas } from '../../Functions/functions'

let firstLoad = true;

const Search = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    // let { products, error } = useSelector(state => state.products)

    // if (firstLoad) products = []

    // const keyword = params.keyword

    // useEffect(() => {
        // if (error) {
        //     console.log("error")
        // }
        // dispatch(getProducts());

    // }, [dispatch, error])

    const [keyword, setKeyword] = useState('');

    const onSearch = (e) => {
        if (e.target.value === "") {
            return setKeyword("NULL")
        }
        firstLoad = false;
        setKeyword(e.target.value);
    }

    const onClick = (id) => {
        setKeyword("NULL") // CLEAR SEARCH FIELD
        // navigate(`/products/${id}`, { state: 'home' })
    }

    return (
        <Fragment>
            <form className="search" role="search">
                <input 
                    id="main-search-field"
                    className="form-control search-field py-2" 
                    type="search" 
                    placeholder="Search products" 
                    aria-label="Search"
                    onChange={(onSearch)}
                />
                {/* <div id="results" className='shadow-lg'>
                    { products && products.filter(product => product.name.toLowerCase().includes(keyword.toLowerCase())).map(product => (
                        <div key={product._id} onClick={function() { onClick(product._id) }}  className="search-item">
                            <div className="search-img" style={{backgroundImage: `url('${product.images[0].url}')`, backgroundSize: 'cover'}}></div>
                            <div className="ms-2">
                                <h4 className="text-size-xs m-0 p-0 text-dark">{product.name}</h4>
                                <span className="text-size-xs green-color">&#x20A6;{numberWithCommas(product.price)}/{product.packaging}</span>
                            </div>
                        </div>
                    ))}
                </div> */}

            </form>
        </Fragment>

    )
}

export default Search