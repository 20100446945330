import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'

import { newClassReducer, getClassesReducer, classDetailsReducer } from './reducers/classReducers'
import { newCourseReducer, enrollCourseReducer, getCoursesReducer, courseDetailsReducer } from './reducers/courseReducers'
import { grantAccessReducer, getAccessListReducer } from './reducers/accessReducers'
import { modifyRecipeReducer, getRecipesReducer, recipeDetailsReducer } from './reducers/recipeReducers'
import { modifyProductReducer, getProductsReducer, productDetailsReducer } from './reducers/productReducers'
import { modifyPromoReducer, getPromosReducer, promoDetailsReducer } from './reducers/promoReducers'
import { modifyOrderReducer, getOrdersReducer, orderDetailsReducer  } from './reducers/orderReducers'
import { modifyShippingAddressReducer, getShippingAddressesReducer, shippingAddressDetailsReducer } from './reducers/shippingAddressReducers'
import { modifyReviewReducer, getReviewsReducer, reviewDetailsReducer} from './reducers/reviewReducers'
import { authReducer, allUsersReducer, updateUserReducer, userDetailsReducer, forgotPasswordReducer } from './reducers/userReducers'
import { cartReducer } from './reducers/cartReducers'
import { getNotificationsReducer } from './reducers/notificationReducers'

const reducer = combineReducers({
    auth: authReducer,
    cart: cartReducer,

    newClass: newClassReducer,
    getClasses: getClassesReducer,
    classDetails: classDetailsReducer,

    newCourse: newCourseReducer,
    enrollCourse: enrollCourseReducer,
    getCourses: getCoursesReducer,
    courseDetails: courseDetailsReducer,

    grantAccess: grantAccessReducer,
    getAccessList: getAccessListReducer,

    recipeDetails: recipeDetailsReducer,
    modifyRecipe: modifyRecipeReducer,
    getRecipes: getRecipesReducer,

    productDetails: productDetailsReducer,
    modifyProduct: modifyProductReducer,
    getProducts: getProductsReducer,

    promoDetails: promoDetailsReducer,
    modifyPromo: modifyPromoReducer,
    getPromos: getPromosReducer,
    
    modifyOrder: modifyOrderReducer,
    getOrders: getOrdersReducer,
    orderDetails: orderDetailsReducer,
    
    shippingAddressDetails: shippingAddressDetailsReducer,
    modifyShippingAddress: modifyShippingAddressReducer,
    getShippingAddresses: getShippingAddressesReducer,
    
    modifyReview: modifyReviewReducer,
    getReviews: getReviewsReducer,
    reviewDetails: reviewDetailsReducer,

    allUsers: allUsersReducer,
    updateUser: updateUserReducer,
    userDetails: userDetailsReducer,
    forgotPassword: forgotPasswordReducer,

    getNotifications: getNotificationsReducer,
})

let initialState = {
    cart: {
        cartItems: sessionStorage.getItem('cartItems') ? JSON.parse(sessionStorage.getItem('cartItems')) : [],
        // shippingInfo: sessionStorage.getItem('shippingInfo') ? JSON.parse(sessionStorage.getItem('shippingInfo')) : {}
    }
}

const middleware = [thunk];
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store;