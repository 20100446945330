import { 
    CREATE_PRODUCT_REQUEST,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_RESET,
    CREATE_PRODUCT_FAIL,
    GET_PRODUCTS_REQUEST,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAIL,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    PRODUCT_DETAILS_RESET,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    UPDATE_PRODUCT_RESET,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAIL,
    DELETE_PRODUCT_RESET,
    CLEAR_ERRORS
} from '../constants/productConstants'

export const getProductsReducer = (state = { products:[] }, action) => {
switch(action.type) {
    case GET_PRODUCTS_REQUEST:
        return {
            loading: true,
            products: []
        }

    case GET_PRODUCTS_SUCCESS:
        return {
            loading: false,
            products: action.payload
        }

    case GET_PRODUCTS_FAIL:
        return {
            loading: false,
            error: action.payload
        }
    case CLEAR_ERRORS:
        return {
            ...state,
            error: null
        }
    default:
        return state;
}
}

export const modifyProductReducer = (state = { product: {} }, action) => {
switch(action.type) {
    case CREATE_PRODUCT_REQUEST:
    case UPDATE_PRODUCT_REQUEST:
    case DELETE_PRODUCT_REQUEST:
        return {
            ...state,
            loading: true,
        }
    case CREATE_PRODUCT_SUCCESS:
    case UPDATE_PRODUCT_SUCCESS:
    case DELETE_PRODUCT_SUCCESS:
        return {
            loading: false,
            success: true,
            product: action.payload.product

        }

    case CREATE_PRODUCT_FAIL:
    case UPDATE_PRODUCT_FAIL:
    case DELETE_PRODUCT_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload
        }

    case CREATE_PRODUCT_RESET:
    case UPDATE_PRODUCT_RESET:
    case DELETE_PRODUCT_RESET:
        return {
            ...state,
            success: false,
        }
    case CLEAR_ERRORS:
        return {
            ...state,
            error: null
        }
    default:
        return state;
}
}

export const productDetailsReducer = (state = {product: {}}, action) => {
    switch (action.type) {
        case PRODUCT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false
            }
        case PRODUCT_DETAILS_SUCCESS:
            return {
                loading: false,
                product: action.payload,
                success: true
            }
        case PRODUCT_DETAILS_FAIL:
            return {
                loading: false,
                success: false,
                error: action.payload
            }
        case PRODUCT_DETAILS_RESET:
            return {
                loading: false,
                success: false,
                product: {}
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}