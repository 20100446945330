import React, { Fragment, useRef, useEffect } from 'react'

import Metadata from '../layout/metadata';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'

import { numberWithCommas } from '../../Functions/functions';

import { PaystackButton } from 'react-paystack'

import Loader from '../layout/loader'

import { enrollCourse, clearErrors } from '../../actions/courseActions'
import { ENROLL_COURSE_RESET } from '../../constants/courseConstants';

import { getAccessList } from '../../actions/accessActions'

const EnrollModal = ({ props }) => {
    const dispatch = useDispatch();
    const params = useParams();

    const { error, loading, success } = useSelector(state => state.enrollCourse);
    const { accessList } = useSelector(state => state.getAccessList);

    useEffect(() => {
        dispatch(getAccessList(params.id));
        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }

        if (success) {
            toast.success("Successfully enrolled!")
            dispatch({type: ENROLL_COURSE_RESET})
            window.location.reload();
        }
    }, [dispatch, error, success]);


    // PAYSTACK
    const componentProps = {
        email: props.user.credential,
        amount: props.price * 100,
        metadata: {
        name: `${props.user.firstName} ${props.user.lastName}`
        },
        publicKey: props.paystackPK,
        text: `Pay ${String.fromCharCode(0x20A6)}${numberWithCommas(props.price)}`,
        onSuccess: () => {
            // order.paidAt = new Date();
            dispatch(enrollCourse(params.id, props.course))
            // navigate('/success')
        },
        onClose: () => {}
    }

    const enrollFree = (e) => {
        dispatch(enrollCourse(params.id, props.course))
    }

    return (
        <Fragment>
            <Metadata title={'Course Details'} />
            <ToastContainer />
            {loading ? <Loader/> : (
                <div className="modal fade" id="enrollModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="enrollModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content text-dark">
                            <div className="modal-header">
                                <span className="modal-title text-sm  bold-2" id="enrollModalLabel">Course Enrollment</span>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>

                            <div className="modal-body text center">
                                <h5 className="text-sm bold-2">Great choice {props.user.firstName}! 🥳</h5>
                                <p className="text-sm mt-3">You're about to enroll for <span className="bold-2">{props.course.name}</span></p>
                            </div>

                            <div className="modal-footer">
                                {accessList.includes(props.user.credential) || props.price === 0 ?
                                <button onClick={enrollFree} className="orange-btn h-auto text-xs border py-2 bold-2" data-bs-dismiss="modal">Enroll for free</button>
                                : <PaystackButton {...componentProps} className="orange-btn h-auto text-xs border py-2 bold-2" data-bs-dismiss="modal"/>
                                }
                                
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        
                        </div>
                    </div>
                </div>
            )}

        </Fragment>
    )
    }

export default EnrollModal;
