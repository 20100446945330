import React, { Fragment, useState, useEffect, useRef } from 'react'
import Metadata from '../layout/metadata'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getProductDetails, clearErrors } from '../../actions/productActions'
import { addItemToCart } from '../../actions/cartActions'


import { useParams } from 'react-router-dom'
import { numberWithCommas } from '../../Functions/functions'

const ShopProductDetails = () => {
    let firstLoad = useRef(true)

    const [quantity, setQuantity] = useState(1)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { discountedPrice } = location.state

    const { user, loading: userLoading } = useSelector(state => state.auth);
    const { loading, product, error } = useSelector(state => state.productDetails)

    const ebook = user && user.ebooks ? user.ebooks.filter(ebook => ebook.id === product._id) : []

    let [ previewImage, setPreviewImage ] = useState('');

    if ( firstLoad.current && product.images) {previewImage = product.images[0].url}

    const params = useParams();

    useEffect(() => {
        dispatch(getProductDetails(params.id))

        // CLEAR SEARCH FIELD
        try {
            document.querySelector('#main-search-field').value = "";

        } catch (error) {
            return
        }

        if (error) {
            toast.error("Error fetching product details, please check your internet connection.")
            dispatch(clearErrors())
        }

    }, [dispatch, error])

    const increaseQty = () => {
        const count = document.querySelector('#count')

        if (count.valueAsNumber >= product.stock) return;
        
        const qty = count.valueAsNumber + 1

        setQuantity(qty)
    }

    const decreaseQty = () => {
        const count = document.querySelector('#count')

        if (count.valueAsNumber <= 1) return;
        
        const qty = count.valueAsNumber - 1

        setQuantity(qty)
    }
    
    const addToCart = () => {
        dispatch(addItemToCart(params.id, quantity, discountedPrice));
        return toast.success("Added to cart 👍")
    }

    const getImage = (e) => {
        firstLoad.current = false;
        setPreviewImage(e.target.src)
    }

    const handleDownload = (name, url) => {
        const pdfUrl = url;
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank'; // Open in a new tab
        link.download = name; // Specify the suggested file name
        link.click();
    };

    const goBack = () => {
        firstLoad.current = true;
        navigate(-1)
    }

    return (
        <Fragment>
            <Metadata title={'Product Details'}/>
            <ToastContainer />

            {/* PRODUCTS SHOW PAGE */}
            <div className="container-md my-4 ps-md-2">
                <div onClick={goBack} className="breadcrumb text-2">{"< Go Back"}</div>
            </div>

            <div className="container-md my-0 mb-md-5 pt-md-5">
                {loading || userLoading || !product.keyFeatures ?                             
                    <div className="my-4">
                        <Skeleton count={15} height={50} baseColor="#dc720082"/>
                    </div> :
                    <Fragment> 
                        <div className="row">
                            <div className="col-sm-6 col-xl-4 d-flex flex-column mb-4 mb-sm-0">
                                <div className="product-details-img" style={{backgroundImage: `url('${previewImage}')`}}></div>
                                <div className="flex mt-3">
                                    { product.images && product.images.map(image => (
                                        <img onClick={getImage} key={image.url} src={image.url} className="toggle-img mx-1"/>
                                    ))}
                                </div>

                            </div>
                            <div className="col-sm-6 col-xl-8 d-flex flex-column">
                                <div>
                                    <h3 className="text-3 bold-2 mb-1">{product.name}</h3>
                                    <span className='text-1 text-muted'>Product #{product._id}</span>
                                    {discountedPrice !== product.price ? 
                                        <p className="text-3 bold-2 my-2">&#x20A6;{numberWithCommas(discountedPrice)} <span className="bold-1 text-muted text-1 text-decoration-line-through">&#x20A6;{numberWithCommas(product.price)}</span></p>
                                    :   <p className="text-3 bold-2 my-2">&#x20A6;{numberWithCommas(product.price)}</p>
                                    }
                                </div>
                                <hr className="light-hr my-2 my-sm-2"/>
                                <div>
                                    <span className="text-1 text-muted my-5">Availability</span>
                                    {product.stock || typeof product.stock === "number" ?
                                    <h4 className="text-2 mt-2"><span className={product.stock > 0 ? 'green' : 'red'}>{product.stock > 0 ? 'In Stock' : 'Out of Stock'}</span></h4>
                                    :<h4 className="text-2 mt-2">Downloadable PDF - <span className="green">In Stock</span></h4>
                                    }
                                </div>
                                <hr className="light-hr my-2 my-sm-2"/>
                                <div>
                                    <span className="text-1 text-muted my-5">Category</span>
                                    <h4 className="text-2 mt-2">{product.category}</h4>
                                </div>
                                {/* <hr className="light-hr my-2 my-sm-2"/>
                                <div>
                                    <span className="text-1 text-muted">Packaging</span>
                                    <h4 className="text-sm mt-2">{product.packaging}</h4>
                                </div>
                                <hr className="light-hr my-2 my-sm-2"/>

                                <div>
                                    <span className="text-1 text-muted">Overall Rating</span>
                                    <h4 className="text-2 mt-2">{product.rating} ({product.numOfReviews} reviews)</h4>
                                </div> */}

                                <hr className="light-hr my-2 my-sm-2"/>

                                {ebook.length > 0 ?
                                    <div className="mt-3">
                                        <button className='text-1 orange-btn bold-2 w-auto' onClick={() => handleDownload(ebook[0].name, ebook[0].doc)}>Download E-book</button>
                                    </div>
                                :   <div className="mt-3">
                                        <div className="d-flex align-items-center">
                                            <button className="d-flex align-items-center justify-content-center qty-toggle" disabled={!product.stock || product.stock === 0}  onClick={decreaseQty}>-</button>
                                            <input type="number" id="count" className="product-qty text-2 mx-2 p-0" value={product.stock === 0 ? 0 : quantity} readOnly/>
                                            <button className="d-flex align-items-center justify-content-center qty-toggle" disabled={!product.stock || product.stock === 0} onClick={increaseQty}>+</button>
                                            <button className="orange-btn text-1 bold-2 ms-4" disabled={product.stock === 0} onClick={addToCart}>Add to Cart</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>  
            
                        <div className='my-5'>
                            <h3 className="text-2">Product Details</h3>
                            <hr className="light-hr my-2 my-sm-2"/>
                            <p className="text-1 text-muted bold-2">Description</p>
                            <p className="text-2">{product.description}</p>

                            <p className="text-1 text-muted bold-2 mt-5 mb-2">Delivery</p>
                            {product.stock || typeof product.stock === "number" ?
                                <p className="text-2">Expect delivery from October 2nd, 2023</p>
                                :<p className="text-2">Accessible immediately after payment</p>
                            }

                            <p className="text-1 text-muted bold-2 mt-5 mb-2">Key Features</p>
                            { product.keyFeatures.split('•').slice(1).map(feature => (
                                <p key={feature} className="text-2 mb-2">● {feature}</p>
                            ))}

                        </div>
                    </Fragment>
                }
            </div>                             
        </Fragment>
    )
}

export default ShopProductDetails