import React, { Fragment } from 'react'

const ProductTargetsModal = ({targets}) => {

    return (
        <Fragment>
            {/* Modal */}
            <div className="modal fade" id="promo-target-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-xs bold-1" id="promo-target-modal-label">Promo Target</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body text-center">
                            {targets.map(target => 
                                <p key={target.value ? target.value : target} className="text-xxs m-1">{target.label ? target.label : target}</p>
                            )}
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary text-2" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
    }

export default ProductTargetsModal
