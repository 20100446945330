export const GET_CLASSES_REQUEST = 'GET_CLASSES_REQUEST'
export const GET_CLASSES_SUCCESS = 'GET_CLASSES_SUCCESS'
export const GET_CLASSES_FAIL = 'GET_CLASSES_FAIL'

export const NEW_CLASS_REQUEST = 'NEW_CLASS_REQUEST'
export const NEW_CLASS_SUCCESS = 'NEW_CLASS_SUCCESS'
export const NEW_CLASS_RESET = 'NEW_CLASS_RESET'
export const NEW_CLASS_FAIL = 'NEW_CLASS_FAIL'

export const CLASS_DETAILS_REQUEST = 'CLASS_DETAILS_REQUEST'
export const CLASS_DETAILS_SUCCESS = 'CLASS_DETAILS_SUCCESS'
export const CLASS_DETAILS_RESET = 'CLASS_DETAILS_RESET'
export const CLASS_DETAILS_FAIL = 'CLASS_DETAILS_FAIL'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'