import React, { Fragment, useState, useEffect } from 'react'

import Metadata from '../layout/metadata';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import Loader from '../layout/loader'

import { deleteRecipe, clearErrors } from '../../actions/recipeActions'

import { DELETE_RECIPE_RESET } from '../../constants/recipeConstants';

const RecipeDeleteModal = ({ props }) => {

    const dispatch = useDispatch();

    const params = useParams();
    const navigate = useNavigate();

    const { error, loading, success } = useSelector(state => state.modifyRecipe);

    useEffect(() => {
        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }

        if (success) {
            toast.success("Successfully deleted!")
            dispatch({type: DELETE_RECIPE_RESET})
            navigate("/admin/recipes")
        }
    }, [dispatch, error, success]);

    const deleteHandler = (event) => {
        dispatch(deleteRecipe(params.name));
    }

    return (
        <Fragment>
            <Metadata title={'Recipe Details'} />
            <ToastContainer />
            {loading ? <Loader/> : (
                <div className="modal fade" id="deleteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content text-dark">
                            <div className="modal-header">
                                <span className="modal-title text-2 bold-2" id="deleteModalLabel">Delete {props.type}</span>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>

                            <div className="modal-body text-center">
                                <p className="text-2 mt-3">Are you sure you want to delete <span className="bold-2">{props.data.name}?</span></p>
                                <p className="text-2 red bold-2">This action is irreversible.</p>
                            </div>

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" onClick={deleteHandler} className="btn btn-danger" data-bs-dismiss="modal">Yes, Delete</button>
                            </div>
                        
                        </div>
                    </div>
                </div>
            )}

        </Fragment>
    )
    }

export default RecipeDeleteModal;
