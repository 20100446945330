import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Metadata from '../layout/metadata'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getRecipeDetails, clearErrors } from '../../actions/recipeActions'

import { initGA, logPageView } from '../../GoogleAnalytics';

const RecipeDetails = () => {

    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    let { loading, error, recipe } = useSelector(state => state.recipeDetails);

    useEffect(() => {
        dispatch(getRecipeDetails(params.name, "User"));
        initGA();
        logPageView();

        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }

    }, [dispatch, error, params.name])

    const handleContextMenu = (e) => {
        e.preventDefault();
    }

    const goBack = (e) => {
        navigate(-1);
    }

    return (
        <Fragment>
            <Metadata title={"Recipe Details"}/>
            <ToastContainer/>
            <div className="container-fluid container-md mb-5">
                <Fragment>
                    <div className="py-3 mb-3 margin-top-70">
                        <span onClick={goBack} className="breadcrumb text-dark text-xs">{"< Back to recipes"}</span>
                    </div>
                
                    {loading || !recipe.image ? 
                        <div>
                            <Skeleton count={6} height={80} baseColor="#dc720082"/>
                        </div> : 
                        <div className="row mb-5">
                            {recipe.video && recipe.video.URL ? 
                                <div className="col-sm-6 col-lg-4">
                                    <video onContextMenu={handleContextMenu} className="recipe-img" controls poster={recipe.image.URL}>
                                        <source src={recipe.video.URL} type={recipe.video.type} />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                                :
                                <div className="col-sm-6 col-lg-4">
                                    <div className="recipe-img" style={{backgroundImage: `url(${recipe.image.URL})`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                                </div>
                            }



                            <div className="col-sm-6 col-lg-8 ps-sm-3 ps-lg-5 mt-4 mt-sm-0">
                                <h3 className="text-sm bold-2 orange">{recipe.name}</h3>
                                
                                <p className="text-xs mt-2 spaced mb-0">{recipe.description}</p>
        
        
                                <div className="row my-4">
                                    {/* INGREDIENTS */}
                                    <div className="col-lg-6 mb-4 mb-lg-0">
                                        <h3 className="text-sm bold-2 orange mb-0 mb-lg-3">Ingredients:</h3>
                                        { recipe.name && recipe.ingredients.split('•').slice(1).map(ingredient => (
                                            <p key={ingredient} className="text-xs my-3 p-0">● {ingredient}</p>
                                        ))}
                                    </div>
        
                                    {/* STEPS */}
                                    <div className="col-lg-6 mt-md-0">
                                        <h3 className="text-sm bold-2 orange mb-0 mb-lg-3">Cooking Steps:</h3>
                                        { recipe.name && recipe.steps.split('•').slice(1).map(step => (
                                            <p key={step} className="text-xs my-3 p-0">● {step}</p>
                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>
                    }

                </Fragment>                          
            </div>
        </Fragment>
    )
}

export default RecipeDetails;