import React, { Fragment, useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import Metadata from '../layout/metadata'
import CheckoutSteps from './checkoutSteps'
import Select from 'react-select'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'

import { newShippingAddress, clearErrors } from '../../actions/shippingAddress'
import { CREATE_SHIPPING_ADDRESS_RESET } from '../../constants/shippingAddressConstants'

const AddShippingAddress = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const cityOptions = [
        { label: 'Ajah', value: 'Ajah' },
        { label: 'Agege', value: 'Agege' },
        { label: 'Agbado', value: 'Agbado' },
        { label: 'Ajegunle', value: 'Ajegunle' },
        { label: 'Alimosho', value: 'Alimosho' },
        { label: 'Apapa', value: 'Apapa' },
        { label: 'Badagry', value: 'Badagry' },
        { label: 'Banana Island', value: 'Banana Island' },
        { label: 'Ebute Metta', value: 'Ebute Metta' },
        { label: 'Epe', value: 'Epe' },
        { label: 'Ibeju-Lekki', value: 'Ibeju-Lekki' },
        { label: 'Ikeja', value: 'Ikeja' },
        { label: 'Ikorodu', value: 'Ikorodu' },
        { label: 'Isheri-Magodo', value: 'Isheri-Magodo' },
        { label: 'Iyana-Paja', value: 'Iyana-Paja' },
        { label: 'Lagos (Eko) city', value: 'Lagos (Eko) city' },
        { label: 'Lagos Island', value: 'Lagos Island' },
        { label: 'Lagos Mainland', value: 'Lagos Mainland' },
        { label: 'Lekki', value: 'Lekki' },
        { label: 'Makoko', value: 'Makoko' },
        { label: 'Mushin', value: 'Mushin' },
        { label: 'Ojo', value: 'Ojo' },
        { label: 'Ojuelegba', value: 'Ojuelegba' },
        { label: 'Oshodi-Isolo', value: 'Oshodi-Isolo' },
        { label: 'Sangotedo', value: 'Sangotedo' },
        { label: 'Somolu', value: 'Somolu' },
        { label: 'Surulere', value: 'Surulere' },
        { label: 'Victoria Island', value: 'Victoria Island' },
        { label: 'Yaba', value: 'Yaba' }
      ]      


    const [shippingAddress, setShippingAddress] = useState({
        street: "",
        city:  "", 
        state:  "Lagos", 
        phoneNumber:  "",
        country: "Nigeria"
    })

    const { street, city, state, phoneNumber, country } = shippingAddress;

    const currState = location.state;

    const { loading, error, success } = useSelector(state => state.modifyShippingAddress)

    useEffect(() => {
        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }
        if (success) {
            toast.success("Successfully added address👍")
            dispatch({ type: CREATE_SHIPPING_ADDRESS_RESET })
            navigate(`/shop/checkout/shipping`, {state: currState})
        }
    }, [dispatch, error, success])


    const shippingAddressOnChange = (event) => {
        setShippingAddress({...shippingAddress, [event.target.name]: event.target.value });
    };

    const cityOnChange = (city) => {
        setShippingAddress({...shippingAddress, ["city"]: city });
    }

    const submitHandler = (e) => {
        e.preventDefault()
        shippingAddress.city = shippingAddress.city.value
        dispatch(newShippingAddress(shippingAddress))
    }
  return (
    <Fragment>
        <Metadata title={'Checkout - Add Shipping Address'}/>
        <ToastContainer />
        <div className="container-md pt-4">
            <CheckoutSteps shipping currState={{...location.state}}/>
            <form onSubmit={submitHandler}>
                <h3 className="text-2 text-muted bold-2 mb-4 mb-md-5 mt-4">Add Shipping Address</h3>
                <div className="row">

                    <div className="col-md-12 mb-3 mb-md-5">
                        <label htmlFor="street" className="form-label">Street</label><i className="red">*</i>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="street"
                            name='street'
                            value={street}
                            onChange={shippingAddressOnChange}
                            aria-describedby="streetHelp" 
                            placeholder="E.g. No 15, Omoye street"
                            required
                        />
                    </div>
                    <div className="col-md-6 mb-3 mb-md-5">
                        <label htmlFor="city" className="form-label">City</label><i className="red">*</i>
                        <Select
                            name="city"
                            className="w-100 form-control"
                            options={cityOptions}
                            value={city}
                            onChange={cityOnChange}
                            aria-describedby="cityHelp"
                            placeholder="E.g. Surulere"
                            required
                        />
                    </div>

                    <div className="col-md-6 mb-3 mb-md-5">
                        <label htmlFor="state" className="form-label">State</label><i className="red">*</i>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="state"
                            name='state'
                            value={state}
                            onChange={shippingAddressOnChange}
                            aria-describedby="stateHelp" 
                            placeholder="E.g. Lagos"
                            readOnly
                            required
                        />
                    </div>

                    <div className="col-md-6 mb-3 mb-md-5">
                        <label htmlFor="phoneNumber" className="form-label">Phone Number</label><i className="red">*</i>
                        <input 
                            type="number" 
                            className="form-control" 
                            id="phoneNumber"
                            name='phoneNumber'
                            value={phoneNumber}
                            onChange={shippingAddressOnChange}                 
                            aria-describedby="phoneNumberHelp" 
                            placeholder="E.g. 09012345678"
                            required
                        />
                    </div>
                    <div className="col-md-6 mb-3 mb-md-5">
                        <label htmlFor="Country" className="form-label">Country</label><i className="red">*</i>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="Country"
                            name='Country' 
                            value = {country}
                            aria-describedby="CountryHelp" 
                            placeholder="E.g. Nigeria"
                            required
                            readOnly
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-4 mb-5">
                    <Link to="/shop/checkout/shipping" state={currState} className="d-flex align-items-center justify-content-center text-2 orange bold-1 me-3">Back to Shipping</Link>
                    <button type="submit" className="d-flex align-items-center justify-content-center orange-btn text-2">{loading? "Adding address" : "Add address"}</button>
                </div>
            </form>
        </div>
    </Fragment>
  )
}

export default AddShippingAddress