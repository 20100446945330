import React, { Fragment, useEffect } from 'react'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { getRecipes, clearErrors } from '../../actions/recipeActions'

import { initGA, logPageView } from '../../GoogleAnalytics';

const UserRecipes = () => {
    const dispatch = useDispatch();

    const { loading, error, recipes } = useSelector(state => state.getRecipes);

    const breakfast = recipes.filter(recipe => recipe.category === "Simple breakfast");
    const plantain = recipes.filter(recipe => recipe.category === "Plantain recipes");
    const soups = recipes.filter(recipe => recipe.category === "Nigerian soups");
    const chicken = recipes.filter(recipe => recipe.category === "Chicken recipes");
    const pasta = recipes.filter(recipe => recipe.category === "Pasta recipes");
    const special = recipes.filter(recipe => recipe.category === "OmoyeCooks special recipes");

    useEffect(() => {
        dispatch(getRecipes());
        initGA();
        logPageView();

        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }

    }, [dispatch, error]);

    const handleContextMenu = (e) => {
        e.preventDefault();
    }

  return (
    <Fragment>
        <ToastContainer/>
        <div className="carousel-image mt-5" style={{backgroundImage: "url('/images/recipes_bg.png')", backgroundPosition: "center", backgroundSize: "cover"}}></div>
        <div className="carousel-text d-block">
            <h5 className="carousel-header bold-2">Special recipes with Omoye</h5>
            <p className="text-sm py-2">Yummy yummy recipes for you!</p>
        </div>

        <div className="container-fluid container-md">
            <div className='text-center mt-5'>
                <h2 className="text-lg">YOU LOVE GOOD FOOD? THESE ARE THE MOST DELICIOUS RECIPES!</h2>
                <hr className="mx-auto w-25" />
                <p className="text-xs spaced mt-4 mb-5">OmoyeCooks recipes are famous for creativity that transforms a regular meal to a super delicacy. 
                Now you have a collection to choose from and “wow” your friends & family every meal time.</p>
            </div>

                            
            {loading  ? 
                <div>
                    <Skeleton count={6} height={80} baseColor="#dc720082"/>
                </div> :
                <Fragment>
                    { breakfast.length > 0 ? 
                        <Fragment>
                            <div className="text-center my-5">
                                <h2 className="text-lg">START YOUR DAY WITH THESE AMAZING BREAKFAST RECIPES!</h2>
                                <hr className="mx-auto w-25" />
                                <p className="text-xs spaced mt-4 mb-5">Follow these easy steps to making the best Breakfast dishes.</p>
                                <div className="wrapper">
                                    { breakfast.map(recipe => (
                                        <Link to={`/recipes/${recipe.name}`} key={recipe._id}  className="wrapper-box shadow-sm">
                                        { recipe.video && recipe.video.URL ? 
                                        <div className="recipe-img-sm">
                                            <video onContextMenu={handleContextMenu} className="recipe-img-sm" controls poster={recipe.image.URL}>
                                                <source src={recipe.video.URL} type={recipe.video.type} />
                                                Your browser does not support the video tag.
                                            </video>
                                            </div>
                                        :
                                            <div className="recipe-img-sm" style={{backgroundImage: `url(${recipe.image.URL})`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                                        }

                                        <div className="text-start px-2 px-md-3 pb-3">
                                            <p className="p-ellipsis text-muted text-xxs mt-3 mb-0">{recipe.category.toUpperCase()}</p>
                                            <p className="p-ellipsis text-sm bold-1 mt-1 mb-0">{recipe.name}</p>
                                        </div>
                                    </Link>
                                    ))}
                                </div> 
                            </div>
                        </Fragment>:<Fragment></Fragment>
                    }

                    { chicken.length > 0 ? 
                        <Fragment>
                            <div className="text-center my-5">
                                <h2 className="text-lg">YOU CANNOT GET ENOUGH OF THESE CHICKEN RECIPES!</h2>
                                <hr className="mx-auto w-25" />
                                <p className="text-xs spaced mt-4 mb-5">Follow these easy steps to making the best Chicken dishes.</p>
                                <div className="wrapper">
                                    { chicken.map(recipe => (
                                        <Link to={`/recipes/${recipe.name}`} key={recipe._id}  className="wrapper-box shadow-sm">
                                        { recipe.video && recipe.video.URL ? 
                                        <div className="recipe-img-sm">
                                            <video onContextMenu={handleContextMenu} className="recipe-img-sm" controls poster={recipe.image.URL}>
                                                <source src={recipe.video.URL} type={recipe.video.type} />
                                                Your browser does not support the video tag.
                                            </video>
                                            </div>
                                        :
                                            <div className="recipe-img-sm" style={{backgroundImage: `url(${recipe.image.URL})`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                                        }

                                        <div className="text-start px-2 px-md-3 pb-3">
                                            <p className="p-ellipsis text-muted text-xxs mt-3 mb-0">{recipe.category.toUpperCase()}</p>
                                            <p className="p-ellipsis text-sm bold-1 mt-1 mb-0">{recipe.name}</p>
                                        </div>
                                    </Link>
                                    ))}
                                </div> 
                            </div>
                        </Fragment>:<Fragment></Fragment>
                    }

                    {/*  SOUPS */}
                    { soups.length > 0 ? 
                        <Fragment>
                            <div className="text-center my-5">
                                <h2 className="text-lg">OH YES, SPECIAL SOUP RECIPES!</h2>
                                <hr className="mx-auto w-25" />
                                <p className="text-xs spaced mt-4 mb-5">Follow these easy steps to making the best Soup dishes.</p>
                                <div className="wrapper">
                                    
                                    { soups.map(recipe => (
                                        <Link to={`/recipes/${recipe.name}`} key={recipe._id}  className="wrapper-box shadow-sm">
                                            { recipe.video && recipe.video.URL ? 
                                            <div className="recipe-img-sm">
                                                <video onContextMenu={handleContextMenu} className="recipe-img-sm" controls poster={recipe.image.URL}>
                                                    <source src={recipe.video.URL} type={recipe.video.type} />
                                                    Your browser does not support the video tag.
                                                </video>
                                                </div>
                                            :
                                                <div className="recipe-img-sm" style={{backgroundImage: `url(${recipe.image.URL})`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                                            }

                                            <div className="text-start px-2 px-md-3 pb-3">
                                                <p className="p-ellipsis text-muted text-xxs mt-3 mb-0">{recipe.category.toUpperCase()}</p>
                                                <p className="p-ellipsis text-sm bold-1 mt-1 mb-0">{recipe.name}</p>
                                            </div>
                                        </Link>
                                    ))}
                                </div> 
                            </div>
                        </Fragment>:<Fragment></Fragment>
                    }

                    { plantain.length > 0 ? 
                        <Fragment>
                            <div className="text-center my-5">
                                <h2 className="text-lg">PLANTAIN RECIPES YOU HAVEN'T SEEN BEFORE!</h2>
                                <hr className="mx-auto w-25" />
                                <p className="text-xs spaced mt-4 mb-5">Follow these easy steps to making the best Plantain dishes.</p>
                                <div className="wrapper">
                                    { plantain.map(recipe => (
                                            <Link to={`/recipes/${recipe.name}`} key={recipe._id}  className="wrapper-box shadow-sm">
                                            { recipe.video && recipe.video.URL ? 
                                            <div className="recipe-img-sm">
                                                <video onContextMenu={handleContextMenu} className="recipe-img-sm" controls poster={recipe.image.URL}>
                                                    <source src={recipe.video.URL} type={recipe.video.type} />
                                                    Your browser does not support the video tag.
                                                </video>
                                                </div>
                                            :
                                                <div className="recipe-img-sm" style={{backgroundImage: `url(${recipe.image.URL})`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                                            }

                                            <div className="text-start px-2 px-md-3 pb-3">
                                                <p className="p-ellipsis text-muted text-xxs mt-3 mb-0">{recipe.category.toUpperCase()}</p>
                                                <p className="p-ellipsis text-sm bold-1 mt-1 mb-0">{recipe.name}</p>
                                            </div>
                                        </Link>
                                    ))}
                                </div> 
                            </div>
                        </Fragment>:<Fragment></Fragment>
                    }

                    { pasta.length > 0 ? 
                        <Fragment>
                            <div className="text-center my-5">
                                <h2 className="text-lg">PASTA RECIPES, BECAUSE YOU LOVE PASTA!</h2>
                                <hr className="mx-auto w-25" />
                                <p className="text-xs spaced mt-4 mb-5">Follow these easy steps to making the best Pasta dishes.</p>
                                <div className="wrapper">
                                    { pasta.map(recipe => (
                                        <Link to={`/recipes/${recipe.name}`} key={recipe._id}  className="wrapper-box shadow-sm">
                                        { recipe.video && recipe.video.URL ? 
                                        <div className="recipe-img-sm bg-white">
                                            <video onContextMenu={handleContextMenu} className="recipe-img-sm bg-white" controls poster={recipe.image.URL}>
                                                <source src={recipe.video.URL} type={recipe.video.type} />
                                                Your browser does not support the video tag.
                                            </video>
                                            </div>
                                        :
                                            <div className="recipe-img-sm bg-white" style={{backgroundImage: `url(${recipe.image.URL})`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                                        }

                                        <div className="text-start px-2 px-md-3 pb-3">
                                            <p className="p-ellipsis text-muted text-xxs mt-3 mb-0">{recipe.category.toUpperCase()}</p>
                                            <p className="p-ellipsis text-sm bold-1 mt-1 mb-0">{recipe.name}</p>
                                        </div>
                                    </Link>
                                    ))}
                                </div> 
                            </div>
                        </Fragment>:<Fragment></Fragment>
                    }

                    { special.length > 0 ? 
                        <Fragment>
                            <div className="text-center my-5">
                                <h2 className="text-lg">SPECIAL, SPECIAL RECIPES, YOU ABSOLUTELY HAVE TO TRY!</h2>
                                <hr className="mx-auto w-25" />
                                <p className="text-xs spaced mt-4 mb-5">Follow these easy steps to prepare OmoyeCooks special recipes.</p>
                                <div className="wrapper">
                                    { special.map(recipe => (
                                        <Link to={`/recipes/${recipe.name}`} key={recipe._id}  className="wrapper-box shadow-sm">
                                        { recipe.video && recipe.video.URL ? 
                                        <div className="recipe-img-sm bg-white">
                                            <video onContextMenu={handleContextMenu} className="recipe-img-sm bg-white" controls poster={recipe.image.URL}>
                                                <source src={recipe.video.URL} type={recipe.video.type} />
                                                Your browser does not support the video tag.
                                            </video>
                                            </div>
                                        :
                                            <div className="recipe-img-sm bg-white" style={{backgroundImage: `url(${recipe.image.URL})`, backgroundPosition: "center", backgroundSize: "cover"}}></div>
                                        }

                                        <div className="text-start px-2 px-md-3 pb-3">
                                            <p className="p-ellipsis text-muted text-xxs mt-3 mb-0">{recipe.category.toUpperCase()}</p>
                                            <p className="p-ellipsis text-sm bold-1 mt-1 mb-0">{recipe.name}</p>
                                        </div>
                                    </Link>
                                    ))}
                                </div> 
                            </div>
                        </Fragment>:<Fragment></Fragment>
                    }
            </Fragment>
            }
        </div> 
    </Fragment>
  )
}

export default UserRecipes;