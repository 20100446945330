import React, { useState, forwardRef, useImperativeHandle, Fragment } from 'react';
import AWS from 'aws-sdk';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const accelerateEndpoint = process.env.REACT_APP_AWS_ACCELERATE_ENDPOINT;
const cloudfront = process.env.REACT_APP_AWS_CLOUDFRONT

const s3 = new AWS.S3({
  accessKeyId,
  secretAccessKey,
  signatureVersion: 'v4',
  accelerateEndpoint
});

const BUCKET = process.env.REACT_APP_AWS_BUCKET
let changeStartFrom;

const UploadFile = forwardRef((props, ref) => {
    const { id, filesToUpload, courseKey, startFrom } = props

    const [uploadProgress, setUploadProgress] = useState(Array.from(filesToUpload, () => 0));
    const [fileStatus, setFileStatus] = useState(Array.from(filesToUpload, () => "Uploading"));
    const [error, setError] = useState('');
    const [retry, setRetry] = useState(false);
    const [currIndex, setCurrIndex] = useState(null);

    const handleUpload = async (index, startFrom) => {
        if (Array.isArray(startFrom)) changeStartFrom = startFrom[index] - index
        else changeStartFrom = startFrom;

        if (filesToUpload.length === 0) return setError('Course content must include videos');

        if (index === filesToUpload.length) {
            toast.success("Successfully modified all files 👍")
            props.sendStatus("Complete");
            return;
        }
        props.sendStatus("Uploading");
        let fileDetails = {}

        if (index === 0 && changeStartFrom === 0) fileDetails.preview = true
        else fileDetails.preview = false;

        const file = filesToUpload[index];

        if (file.image) {
            const extArr = file.image.name.split('.');
            const ext = extArr[extArr.length - 1];
            const key = `Courses/${courseKey}/video${index + changeStartFrom}/${courseKey}-image${index + changeStartFrom}.${ext}`;

            const params = {
                Bucket: BUCKET,
                Key: key,
                Body: file.image,
            };

            fileDetails.thumbnailName = file.image.name;
            fileDetails.thumbnailType = file.image.type;
            fileDetails.thumbnailSize = (parseFloat(file.image.size) * 0.0000009537).toFixed(2) + "MB";
            fileDetails.thumbnailURL = `${cloudfront}/${key}`;

            await s3.upload(params).promise();

            if (!file.video) {
                fileDetails.isOnlyImage = true;
                fileDetails.videoPosition = index + changeStartFrom;
                setUploadProgress((prevProgress) => {
                    const updatedProgress = [...prevProgress];
                    updatedProgress[index] = 100;
                    return updatedProgress;
                });
                fetch(`/api/v1/admin/academy/${id}/add-videos`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                      },
                    body: JSON.stringify(fileDetails)
                })
                .then((response) => {
                    // console.log(response.json())
                    setFileStatus((prevStatus) => {
                        const updatedStatus = [...prevStatus];
                        updatedStatus[index] = "Complete";
                        return updatedStatus;
                    });
                })
                .then(() => {
                    handleUpload(index + 1, startFrom);
                })
                .catch((error) => {
                    setError('Upload was successful, but there was an error sending data to the server, contact support.');
                    // console.log('Error sending data to server: ', error);
                    props.sendStatus("Complete");
                });
            }
        }

        if (file.video) {
            const extArr = file.video.name.split('.');
            const ext = extArr[extArr.length - 1];
            const key = `Courses/${courseKey}/video${index + changeStartFrom}/${courseKey}-video${index + changeStartFrom}.${ext}`;

            const params = {
                Bucket: BUCKET,
                Key: key,
                Body: file.video,
            };

            fileDetails.videoName = file.video.name;
            fileDetails.videoType = file.video.type;
            fileDetails.videoSize = (parseFloat(file.video.size) * 0.0000009537).toFixed(2) + "MB";
            fileDetails.videoPosition = index + changeStartFrom;
            fileDetails.videoURL = `${cloudfront}/${key}`;

            const managedUpload = new AWS.S3.ManagedUpload({
                params,
                service: s3
            });

            managedUpload.on('httpUploadProgress', (progress) => {
                const percentUploaded = Math.round((progress.loaded / progress.total) * 100);
                setUploadProgress((prevProgress) => {
                const updatedProgress = [...prevProgress];
                updatedProgress[index] = percentUploaded;
                return updatedProgress;
                });
                setError('');
            });
        
            managedUpload.send((err, data) => {
                if (err) {
                setError('Error uploading file: ' + file.video.name + ' - Please check your internet connection, and retry...');
                setCurrIndex(index);
                setRetry(true);
                return;
                }

                // console.log('File uploaded successfully: ', data);
                
                fetch(`/api/v1/admin/academy/${id}/add-videos`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                      },
                    body: JSON.stringify(fileDetails)
                })
                .then((response) => {
                    // console.log(response.json())

                    setFileStatus((prevStatus) => {
                        const updatedStatus = [...prevStatus];
                        updatedStatus[index] = "Complete";
                        return updatedStatus;
                    });
                })
                .then(() => {
                    handleUpload(index + 1, startFrom);
                })
                .catch((error) => {
                    setError('Upload was successful, but there was an error sending data to the server, contact support.');
                    // console.log('Error sending data to server: ', error);
                    props.sendStatus("Complete");
                });
            });
        }
    };

    const startUpload = () => {
        if (filesToUpload.length === 0) {
            setError('Course content must include videos');
        return;
        }

        setError('');
        handleUpload(0, startFrom);
    };

    const retryUpload = (e, index, startFrom) => {
        setError('');
        handleUpload(index, startFrom);
        setRetry(false);
    };

    useImperativeHandle(ref, () => ({
        startUpload: startUpload,
      }));

  return (
    <Fragment>
        <div className="d-flex justify-content-center align-items-center mt-0">
            <ToastContainer />
            {filesToUpload.length > 0 &&
            Array.from(filesToUpload).map((file, index) => (
                <div key={index} className="text-center">
                    <div className="mx-2" style={{ width: '55px' }}>
                        <CircularProgressbar 
                        value={uploadProgress[index]} 
                        text={`${uploadProgress[index]}%`}
                        strokeWidth={10}
                        
                        styles={buildStyles({
                        textColor: fileStatus[index] === "Complete" ? "green" : "orange",
                        textSize: "24px",
                        pathColor: fileStatus[index] === "Complete" ? "green" : "orange",
                        trailColor: "grey",
                        strokeLinecap: "butt"
                        })} />
                    </div>
                </div>
            ))}
        </div>
        {error && <div className="text-xs text-center mt-3" style={{ color: 'red' }}>{error}</div>}

        {retry ? 
            <div className="text-center mt-3">
                <button onClick={e => retryUpload(e, currIndex, props.startFrom)} className="action-btn text-xxs">Retry</button>
            </div>
        : <Fragment></Fragment>}

    </Fragment>
  );
});

export default UploadFile;
