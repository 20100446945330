import { 
    CREATE_PROMO_REQUEST,
    CREATE_PROMO_SUCCESS,
    CREATE_PROMO_RESET,
    CREATE_PROMO_FAIL,
    GET_PROMOS_REQUEST,
    GET_PROMOS_SUCCESS,
    GET_PROMOS_FAIL,
    PROMO_DETAILS_REQUEST,
    PROMO_DETAILS_SUCCESS,
    PROMO_DETAILS_FAIL,
    PROMO_DETAILS_RESET,
    UPDATE_PROMO_REQUEST,
    UPDATE_PROMO_SUCCESS,
    UPDATE_PROMO_FAIL,
    UPDATE_PROMO_RESET,
    DELETE_PROMO_REQUEST,
    DELETE_PROMO_SUCCESS,
    DELETE_PROMO_FAIL,
    DELETE_PROMO_RESET,
    CLEAR_ERRORS
} from '../constants/promoConstants'

export const getPromosReducer = (state = { promos:[] }, action) => {
switch(action.type) {
    case GET_PROMOS_REQUEST:
        return {
            loading: true,
            promos: []
        }

    case GET_PROMOS_SUCCESS:
        return {
            loading: false,
            promos: action.payload
        }

    case GET_PROMOS_FAIL:
        return {
            loading: false,
            error: action.payload
        }
    case CLEAR_ERRORS:
        return {
            ...state,
            error: null
        }
    default:
        return state;
}
}

export const modifyPromoReducer = (state = { promo: {} }, action) => {
switch(action.type) {
    case CREATE_PROMO_REQUEST:
    case UPDATE_PROMO_REQUEST:
    case DELETE_PROMO_REQUEST:
        return {
            ...state,
            loading: true,
        }
    case CREATE_PROMO_SUCCESS:
    case UPDATE_PROMO_SUCCESS:
    case DELETE_PROMO_SUCCESS:
        return {
            loading: false,
            success: true,
            promo: action.payload.promo

        }

    case CREATE_PROMO_FAIL:
    case UPDATE_PROMO_FAIL:
    case DELETE_PROMO_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload
        }

    case CREATE_PROMO_RESET:
    case UPDATE_PROMO_RESET:
    case DELETE_PROMO_RESET:
        return {
            ...state,
            success: false,
        }
    case CLEAR_ERRORS:
        return {
            ...state,
            error: null
        }
    default:
        return state;
}
}

export const promoDetailsReducer = (state = {promo: {}}, action) => {
    switch (action.type) {
        case PROMO_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                success: false
            }
        case PROMO_DETAILS_SUCCESS:
            return {
                loading: false,
                promo: action.payload,
                success: true
            }
        case PROMO_DETAILS_FAIL:
            return {
                loading: false,
                success: false,
                error: action.payload
            }
        case PROMO_DETAILS_RESET:
            return {
                loading: false,
                success: false,
                promo: {}
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}