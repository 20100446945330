import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Metadata from '../layout/metadata'
import AdminNavigation from '../layout/adminNavigation'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { getCourses, clearErrors } from '../../actions/courseActions'

import { NEW_COURSE_RESET } from '../../constants/courseConstants'

import { numberWithCommas } from '../../Functions/functions';

let clicked = "All";
let firstLoad = true;

const Courses = () => {

    const dispatch = useDispatch();

    const { loading, error, courses } = useSelector(state => state.getCourses);

    let [ filteredCourses, setFilteredCourses ] = useState(courses)

    if (firstLoad) { filteredCourses = courses };

    useEffect(() => {
        dispatch(getCourses("Admin"));
        dispatch({ type: NEW_COURSE_RESET })

        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            console.log(error, "ERRORRR - academy.jsx")
            dispatch(clearErrors())
        }

    }, [dispatch, error])


    const onSearchChange = (e) => {
        if (e.target.value === "") {
            return setFilteredCourses(courses)
        }
        let matches = courses.filter(courses => courses.name.toLowerCase().includes(e.target.value.toLowerCase()))

        setFilteredCourses(matches);
        firstLoad = false;
    }

    const toggleTable = (courselevel) => {
        firstLoad = false
        let filter
        clicked = courselevel
        if (courselevel === "All") {
            filter = courses;
        } else if (courselevel === "Free") {
            filter = courses.filter(courses => courses.price === 0)
        } else if (courselevel === "Paid") {
            filter = courses.filter(courses => courses.price > 0)
        } else {
            filter = []
        }

        setFilteredCourses(filter)
    }

    const rowData = [];

    return (
        <Fragment>
            <Metadata title={"Academy"}/>
            <AdminNavigation Academy />
            <ToastContainer/>
            <div className="container-fluid container-md">
                <Fragment>
                    <div className="d-flex align-items-center mt-3">
                        <h3 className="text-xs text-muted d-inline-block m-0 me-4 bold-2">COURSES</h3>
                        <div className="d-flex ms-auto align-items-center">
                            <input
                            className="form-control d-inline-block py-1" 
                            type="search" 
                            placeholder="Search courses"
                            aria-label="Search"
                            onChange={onSearchChange}
                            />
                            <Link to="/admin/academy/add-course" className="text-xs bold-2 d-none d-sm-block add-btn w-75 ms-2">+ Add course</Link>
                            <Link to="/admin/academy/add-course" className="d-sm-none px-3 bold-3 add-btn ms-2">+</Link>
                        </div>
                    </div>

                    {/* FILTER */}
                    <div className="d-flex flex-column flex-md-row justify-content-between mt-3">
                        <span className="text-muted text-xs order-1 order-md-2">{filteredCourses.length} {clicked === 'All' ? 'courses' : clicked + " courses"}</span>
                        <div className="table-nav-block order-2 order-md-1 mt-3 mt-md-0">
                            <button onClick={function() { toggleTable('All') }} className={`table-nav ms-0 ${clicked === 'All'?'table-active':''}`}>All</button>
                            <button onClick={function() { toggleTable('Free') }} className={`table-nav ${clicked === 'Free'?'table-active':''}`}>Free</button>
                            <button onClick={function() { toggleTable('Paid') }} className={`table-nav ${clicked === 'Paid'?'table-active':''}`}>Paid</button>
                        </div>
                    </div>
                    {/* FILTER END */}

                    {loading ? 
                        <Fragment>
                            <div className="my-4">
                                <Skeleton count={15} height={20} baseColor="#dc720082"/>
                            </div>
                        </Fragment> : (
                        <Fragment>
                            <table className="table mt-3 mb-5">
                                <thead className="table-secondary py-5">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Price</th>
                                        <th scope="col" className="text-center">Course Dur</th>
                                        <th scope="col" className="text-center">Videos</th>
                                        <th scope="col" className="text-center">Vid Dur</th>
                                        <th scope="col" className="text-center">Status</th>
                                        <th scope="col" className="text-center">Visibility</th>
                                        <th scope="col" className="text-center">Enrolled</th>
                                        <th scope="col" className="text-center">Start</th>
                                        <th scope="col" className="text-center">End</th>
                                        <th scope="col" className="text-center">Action</th>
                                    </tr>
                                </thead>

                                {filteredCourses.forEach(course => rowData.push(
                                        <tr className="bg-white" key={course._id}>
                                            <td className="text-xs">{course.name}</td>
                                            <td className="text-xs">&#x20A6;{numberWithCommas(course.price)}</td>
                                            <td className="text-xs text-center">{course.duration}</td>
                                            <td className="text-xs text-center">{course.courseVideos.length}</td>
                                            <td className="text-xs text-center">{Math.ceil(course.totalVideoDuration / 60)} mins</td>
                                            <td className="text-xs text-center">{course.courseVideos.filter(video => video.unlocked).length > 0 ? "Active" : "Inactive"}</td>
                                            <td className="text-xs text-center">{course.hidden ? "Hidden" : "Visible"}</td>
                                            <td className="text-xs text-center">{course.studentsEnrolled.length}</td>
                                            <td className="text-xs text-center">{course.startDate.split('T')[0]}</td>
                                            <td className="text-xs text-center">{course.endDate.split('T')[0]}</td>
                                            <td className="text-center text-xs"><Link to={`/admin/academy/${course._id}`} className="orange bold-2">View</Link></td>
                                        </tr>                                 
                                ))}
                                <tbody>{rowData}</tbody>
                            </table>
                            { filteredCourses.length === 0 ? (
                            <div className="text-center mb-5 w-100">
                                <h3 className='text-sm'>NO DATA</h3>
                            </div>
                            ):(<Fragment></Fragment>)}

                        </Fragment>
                    )}
                
                </Fragment>                              
                {/* )} */}
            </div>
        </Fragment>
    )
}

export default Courses;