import axios from 'axios';
import { ADD_TO_CART,
        REMOVE_FROM_CART,
        EMPTY_CART,
        SAVE_PAYMENT_METHOD,
        SAVE_SHIPPING_INFO
} from '../constants/cartConstants'

export const addItemToCart = (id, quantity, discountedPrice) => async (dispatch, getState) => {
    try {
        const { data } = await axios.get(`/api/v1/products/${id}`);

        dispatch({
            type: ADD_TO_CART,
            payload: {
                product: data.product._id,
                name: data.product.name,
                price: discountedPrice !== data.product.price ? discountedPrice : data.product.price,
                image: data.product.images[0].url,
                stock: data.product.stock,
                seller: data.product.seller,
                quantity,
            },
        });

        // Update session storage with cart items
        const updatedCartItems = [...getState().cart.cartItems];
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    } catch (error) {
        // Handle error
        console.error('Error adding item to cart:', error);

        // Clear cart items in session storage
        sessionStorage.setItem('cartItems', JSON.stringify([]));
    }
}

export const removeItemFromCart = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: REMOVE_FROM_CART,
            payload: id,
        });

        // Update session storage with cart items
        const updatedCartItems = [...getState().cart.cartItems];
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    } catch (error) {
        // Handle error
        console.error('Error removing item from cart:', error);
        // Clear cart items in session storage
        sessionStorage.setItem('cartItems', JSON.stringify([]));
    }
};

// Action creator to empty the cart
export const emptyCart = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: EMPTY_CART,
        });

        // Update session storage with cart items
        const updatedCartItems = [...getState().cart.cartItems];
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    } catch (error) {
        // Handle error
        console.error('Error emptying cart:', error);
        // Clear cart items in session storage
        sessionStorage.setItem('cartItems', JSON.stringify([]));
    }
};

export const saveShippingInfo = (data) => async (dispatch) => {
    dispatch({
        type: SAVE_SHIPPING_INFO,
        payload: data
    })

    localStorage.setItem('shippingInfo', JSON.stringify(data))
}

export const savePaymentMethod = (data) => async (dispatch) => {
    dispatch({
        type: SAVE_PAYMENT_METHOD,
        payload: data
    })

    localStorage.setItem('paymentMethod', JSON.stringify(data))
}