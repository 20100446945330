import React, { Fragment, useState, useEffect } from 'react'

import Metadata from '../layout/metadata';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'

import Loader from '../layout/loader'

import { newReview, updateReview, clearErrors } from '../../actions/reviewActions'

import { UPDATE_REVIEW_RESET } from '../../constants/reviewConstants';

function setStar(classname, rating) {
    let star = document.querySelectorAll(classname)

    star.forEach((star, index) => {
        if (index < rating) {
            star.classList.add('orange');
        }
    })
}

const ReviewModal = ({ props }) => {

    const dispatch = useDispatch();

    const params = useParams();
    const navigate = useNavigate();

    const { error, loading, success } = useSelector(state => state.modifyReview);

    const [review, setReview] = useState({
        itemId: props.itemId,
        itemName: props.itemName,
        type: props.type,
        header: props.header ? props.header : "",
        comment: props.comment ? props.comment : "",
        user: props.userId
      });

      const [rating, setRating] = useState(props.rating);

    const { header, comment } = review;

    useEffect(() => {
        // STAR EFFECT
        document.querySelectorAll('.star-dynamic').forEach((star, index) => {

            star.starValue = index + 1;

            ['click', 'mouseover', 'mouseout'].forEach(function(e) {
                star.addEventListener(e, showRatings)
            })
        })

        if (props.header && props.comment) {
            setStar(".star-dynamic", props.rating)
        }

        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }

        if (success) {
            toast.success("Successfully submitted!")
            dispatch({type: UPDATE_REVIEW_RESET})
            window.location.reload()
        }
    }, [dispatch, error, success]);

    function showRatings(event) {
        document.querySelectorAll('.star-dynamic').forEach((star, index) => {
            if (event.type === 'click') {
                if (index < this.starValue) {
                    star.classList.add('orange');
                } else {
                    star.classList.remove('orange');
                }
                setRating(this.starValue);
            }
            if (event.type === 'mouseover') {
                if(index < this.starValue) {
                    star.classList.add('yellow');
                } else {
                    star.classList.remove('yellow');
                }
            }
            if (event.type === 'mouseout') {
                star.classList.remove('yellow');
            }
        })
    }
    // const nameInputHandler = (event) => {
    //     const textRegex = /^[a-zA-Z0-9\s]+$/;
    //     if (textRegex.test(event.target.value)) {
    //       setRecipe({...recipe, [event.target.name]: event.target.value });
    //     }
    //   };

    const OnChange = (event) => {
        setReview({...review, [event.target.name]: event.target.value });
      };

    const submitHandler = (event) => {
        event.preventDefault()
        if (rating === 0) {
            return toast.error("Please select a rating")
        }
        review.rating = rating

        if (props.header && props.comment) {
            dispatch(updateReview(params.id, review))
        } else {
            dispatch(newReview(review))
        }

    }

    return (
        <Fragment>
            <Metadata title={'Course Details'} />
            <ToastContainer />
            {loading ? <Loader/> : (
                <div className="modal fade" id="reviewModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="reviewModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content text-dark">
                            <div className="modal-header">
                                <span className="modal-title text-sm bold-2" id="reviewModalLabel">Review {props.type}</span>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form onSubmit={submitHandler}>
                                <div className="modal-body py-5">
                                    {/* STAR */}
                                    <div className="mb-4">
                                        <p className="text-xs bold-1">Select rating</p>
                                        <ul className="stars p-0">
                                            <li className="star"><i className="fa-solid fa-star star-dynamic"></i></li>
                                            <li className="star mx-2"><i className="fa-solid fa-star star-dynamic"></i></li>
                                            <li className="star"><i className="fa-solid fa-star star-dynamic"></i></li>
                                            <li className="star mx-2"><i className="fa-solid fa-star star-dynamic"></i></li>
                                            <li className="star"><i className="fa-solid fa-star star-dynamic"></i></li>
                                        </ul>
                                    </div>

                                    {/* HEADER */}
                                    <div className="mb-4">
                                    <label htmlFor="header" className="form-label">Header (Max - 40 characters)</label><i className="red">*</i>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            id="header"
                                            name='header'
                                            maxLength={40}
                                            onChange={OnChange}
                                            value={header} 
                                            aria-describedby="headerHelp" 
                                            placeholder="E.g. Super amazing course!"
                                            readOnly={loading}
                                            required
                                        />
                                    </div>

                                    {/* COMMENT */}
                                    <div className="col-12">
                                        <label htmlFor="comment" className="form-label">Comment (Max - 180 characters)</label><i className="red">*</i>
                                        <textarea 
                                            className="form-control" 
                                            id="comment" 
                                            name='comment'
                                            rows="3"
                                            maxLength={180}
                                            value={comment} 
                                            onChange={OnChange}
                                            placeholder="Enter detailed review..."
                                            readOnly={loading}
                                            required
                                        />
                                    </div> 

                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>

                            </form>
                        
                        </div>
                    </div>
                </div>
            )}

        </Fragment>
    )
    }

export default ReviewModal;
