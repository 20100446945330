import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Metadata from '../layout/metadata'

import { resetPassword, clearErrors } from '../../actions/userActions'

const ResetPassword = () => {

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const { error, loading, success } = useSelector(state => state.forgotPassword);

    useEffect(() => {

        if (error) {
            toast.error(error)
            console.error(error)
            dispatch(clearErrors());
        }

        if (success) {
            toast.success("Password updated successfully!")
            navigate('/signin')
        }

    }, [dispatch, success, error])
 
    const submitHandler = (e) => {
        e.preventDefault();

        if (!password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)) {
            return toast.error("Password does not match the required format.")
        }

        if (password !== confirmPassword) return toast.error("Passwords do not match!")
        dispatch(resetPassword(params.token, {password : password, confirmPassword : confirmPassword}));
    }

    return (
        <Fragment>
            <Fragment>
                <Metadata title={'Reset Password'} />
                <ToastContainer />
                <div className="container-fluid container-md">
                    <div className="row shadow-lg mid-container">
                        <div className="col-md-8 p-md-4">
                        <form className="px-2" onSubmit={submitHandler}>
                                <h3 className="text-sm bold-2 my-5">Reset Password</h3>
                                <div className="mb-3">
                                    <label htmlFor="Password" className="form-label">New Password</label>
                                    <input 
                                        type="password"
                                        name='password'
                                        minLength="6" 
                                        className="form-control" 
                                        id="Password" 
                                        placeholder="**********"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                    {password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)
                                    ? <div id="passHelp" className="text-xxs mt-2 bold-2 green">Looks good!</div>
                                    : <div id="passHelp" className="text-xxs mt-2 bold-2 red">Password must have a minimum of 8 characters with at least 1 letter and 1 number.</div>
                                    }
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                    <input 
                                        type="password" 
                                        name='password'
                                        minLength="6"
                                        className="form-control" 
                                        id="confirmPassword" 
                                        placeholder="**********"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                </div>

                                <button 
                                    type="submit" 
                                    className="text-size-xs action-btn mb-5 w-100"
                                    disabled={loading ? true : false}
                                    >{loading ? "Resetting password, please wait..." : "Reset password"}
                                </button>
                            </form>
                        </div>
                        
                        <div className="col-md-4 d-none d-md-block text-end" style={{backgroundImage: "url('/images/signin-img.jpg')", backgroundSize: "cover", backgroundPosition: "center"}}>
                        </div>
                    </div>
                </div>    
            </Fragment>     
        </Fragment>
    )}

export default ResetPassword;