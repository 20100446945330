import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Metadata from '../layout/metadata'

import { forgotPassword, clearErrors } from '../../actions/userActions'

const ForgotPassword = () => {

    let [credential, setCredential] = useState('')

    const dispatch = useDispatch();

    const { error, loading, message } = useSelector(state => state.forgotPassword);

    useEffect(() => {

        if (error) {
            toast.error(error)
            dispatch(clearErrors());
        }

        if (message) {
            toast.success(message)
        }

    }, [dispatch, message, error])
 
    const submitHandler = (e) => {
        e.preventDefault();
        credential = credential.toLowerCase();
        credential = credential.trim();
        dispatch(forgotPassword(credential));
    }

    return (
        <Fragment>
            <Fragment>
                <Metadata title={'Forgot Password'} />
                <ToastContainer />
                <div className="container-fluid container-md">
                    <div className="row shadow-lg mid-container">
                        <div className="col-md-8 p-md-4">
                            <form className="px-2" onSubmit={submitHandler}>
                                <div className="mb-3">
                                    <h3 className="text-sm bold-2 my-5">Forgot Password</h3>
                                    <label htmlFor="credential" className="form-label">Email address</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="credential" 
                                        aria-describedby="credentialHelp" 
                                        placeholder="E.g. john@email.com"
                                        value={credential}
                                        onChange={(e) => setCredential(e.target.value)}
                                        required
                                    />
                                </div>

                                <button 
                                    type="submit" 
                                    className="text-xs action-btn w-100 mt-3 mb-5"
                                    disabled={loading ? true : false}
                                >{loading ? "Sending reset email..." : "Send reset email"}
                                </button>
                            </form>
                        </div>
                        
                        <div className="col-md-4 d-none d-md-block text-end" style={{backgroundImage: "url('/images/signin-img.jpg')", backgroundSize: "cover", backgroundPosition: "center"}}>
                        </div>
                    </div>
                </div>    
            </Fragment>     
        </Fragment>
    )}

export default ForgotPassword;