import React, {Fragment, useState, useCallback} from 'react';
import { useDropzone } from 'react-dropzone';
import 'dropzone/dist/dropzone.css';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export function PreviewDropzone(props) {

    const [previewImage, setPreviewImage] = useState(props.initialImage);
    const [previewVideo, setPreviewVideo] = useState(props.initialVideo);

    props.onChange("Preview", [previewImage, previewVideo])
  
    const handleAcceptedFiles = (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const fileType = file.type.split("/")[0];
        if (fileType === "image") {
          setPreviewImage(file);
        } else if (fileType === "video") {
          setPreviewVideo(file);
        }
      });
    };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*, video/*",
    maxFiles: 2,
    onDrop: (acceptedFiles, fileRejections) => {
      handleAcceptedFiles(acceptedFiles);
    }, 
  });

  const removeFile = (file) => () => {
    if (file.type.split('/')[0] === "image") {
        return setPreviewImage(null)
    }
    setPreviewVideo(null)
  };

  const clearFiles = (event) => {
    event.stopPropagation();
    setPreviewImage(null)
    setPreviewVideo(null)
};


  return (
    <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''} px-1`}>
      <input {...getInputProps()} />
      <p className="text-xs bold-2">Drag and drop files here or click to select files</p>
        <div className="d-flex justify-content-center my-4">
            {!previewImage ? <img src="/images/image-icon.png" alt="" className="file-icon" /> : <Fragment></Fragment> }
            {previewImage && (
                <div className="file-preview me-2">
                    <div className="image-preview" style={{ backgroundImage: `url(${URL.createObjectURL(previewImage)})` }}></div>
                    <div className="file-type">{previewImage.type.split('/')[0]}</div>
                    <button className="file-remove bold-2 text-xxs" onClick={(event) => {
                        event.stopPropagation();
                        removeFile(previewImage)();
                        }}>
                        x
                    </button>
                </div>
            )}
            {!previewVideo ? <img src="/images/video-icon.png" alt="" className="file-icon" /> : <Fragment></Fragment> }
            {previewVideo && 
                <div className="file-preview">
                    <video className="video-preview" src={URL.createObjectURL(previewVideo)} controls />
                    <div className="file-type">{previewVideo.type.split('/')[0]}</div>
                    <button className="file-remove bold-2 text-xxs" onClick={(event) => {
                  event.stopPropagation();
                  removeFile(previewVideo)();
                }}>
                x
              </button>
                </div>
            }
        </div>

      {(previewImage || previewVideo) && <button className="orange-btn text-xs bold-2 mb-4" onClick={clearFiles}>Clear selection</button>}
      {(!previewImage && !previewVideo) && <p className="text-xs bold-2">No files added</p>}
      {((previewImage || previewVideo) || (!previewImage && !previewVideo)) && <p className="text-xs bold-2">Please drop only one image and one video</p>}
    </div>
 
  );
}

export const CourseImagesDropzone = (props) => {
    const [courseImages, setCourseImages] = useState(props.initialValue);
    props.onChange("Course Images", courseImages)

    const onDrop = useCallback((acceptedFiles) => {
        let filteredFiles = acceptedFiles.filter(file => file.type.split("/")[0] === "image")
        setCourseImages((prevFiles) => [...prevFiles, ...filteredFiles]);

    }, []);
  
    const onDragEnd = (result) => {
      if (!result.destination) return;
  
      const newFiles = Array.from(courseImages);
      const [removed] = newFiles.splice(result.source.index, 1);
      newFiles.splice(result.destination.index, 0, removed);
      setCourseImages(newFiles);
    };
  
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: "image/*",
      onDrop,
    });
  
    const removeFile = (file) => () => {
      const newFiles = [...courseImages];
      newFiles.splice(newFiles.indexOf(file), 1);
      setCourseImages(newFiles);
    };
  
    const clearFiles = (event) => {
        event.stopPropagation();
        setCourseImages([]);
    };
  
    const filePreviews = courseImages.map((file, index) => {
  
      let preview = (
            <div
            className="image-preview me-2"
            style={{ backgroundImage: `url("${URL.createObjectURL(file)}")` }}
            onClick={(event) => {
                event.stopPropagation();
            }}
            />
        );
      
  
      return (
        <Draggable key={file.name} draggableId={file.name} index={index}>
          {(provided) => (
            <div
              className="file-preview me-2 mb-2"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {preview}
              <div className="file-type">{file.type.split("/")[0]}</div>
              <button className="file-remove bold-2 text-xxs" onClick={(event) => {
                  event.stopPropagation();
                  removeFile(file)();
                }}>
                x
              </button>
            </div>
          )}
        </Draggable>
      );
    });
  
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <div {...getRootProps()} className="dropzone">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="text-xs bold-2">Drop the images here ...</p>
          ) : courseImages.length > 0 ?(
            <p className="text-xs bold-2">You can move images around to re-order them</p>
          ) : (
            <p className="text-xs bold-2">Drag and drop your images here, or click to select images</p>
          )}
          <Droppable droppableId="droppable" direction={window.innerWidth < 600 ? "vertical" : "horizontal"}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="file-list my-4">
                {filePreviews}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="text-center">
          {courseImages.length > 0 ? <button className="orange-btn text-xs bold-2 mb-4" onClick={clearFiles}>Clear selection</button>
          : <img src="/images/images-icon.png" alt="" className="file-icon mb-5" />}
          </div>
        </div>
      </DragDropContext>
    );
};

export const CourseVideosDropzone = (props) => {
    const [courseVideos, setCourseVideos] = useState(props.initialValue);
    props.onChange("Course Videos", courseVideos)
  
    const onDrop = useCallback((acceptedFiles) => {
        let filteredFiles = acceptedFiles.filter(file => file.type.split("/")[0] === "video")
        setCourseVideos((prevFiles) => [...prevFiles, ...filteredFiles]);
    }, []);
  
    const onDragEnd = (result) => {
      if (!result.destination) return;
  
      const newFiles = Array.from(courseVideos);
      const [removed] = newFiles.splice(result.source.index, 1);
      newFiles.splice(result.destination.index, 0, removed);
      setCourseVideos(newFiles);
    };
  
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      accept: "image/*",
      onDrop,
    });
  
    const removeFile = (file) => () => {
      const newFiles = [...courseVideos];
      newFiles.splice(newFiles.indexOf(file), 1);
      setCourseVideos(newFiles);
    };
  
    const clearFiles = (event) => {
        event.stopPropagation();
        setCourseVideos([]);
    };
  
    const filePreviews = courseVideos.map((file, index) => {
  
      let preview = (
            <video className="video-preview" src={URL.createObjectURL(file)} controls />
        );
      
  
      return (
        <Draggable key={file.name} draggableId={file.name} index={index}>
          {(provided) => (
            <div
              className="file-preview me-2 mb-2"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {preview}
              <div className="file-type">{file.type.split("/")[0]}</div>
              <button className="file-remove bold-2 text-xxs" onClick={(event) => {
                  event.stopPropagation();
                  removeFile(file)();
                }}>
                x
              </button>
            </div>
          )}
        </Draggable>
      );
    });
  
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <div {...getRootProps()} className="dropzone">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="text-xs bold-2">Drop the videos here ...</p>
          ) : courseVideos.length > 0 ?(
            <p className="text-xs bold-2">You can move videos around to re-order them</p>
          ) : (
            <p className="text-xs bold-2">Drag and drop your videos here, or click to select videos</p>
          )}
          <Droppable droppableId="droppable" direction={window.innerWidth < 600 ? "vertical" : "horizontal"}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="file-list my-4">
                {filePreviews}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="text-center">
          {courseVideos.length > 0 ? <button className="orange-btn text-xs bold-2 mb-4" onClick={clearFiles}>Clear selection</button>
          : <img src="/images/videos-icon.png" alt="" className="file-icon mb-5" />}
          </div>
        </div>
      </DragDropContext>
    );
};


