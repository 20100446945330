import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Metadata from '../layout/metadata'

import { changePassword, clearErrors } from '../../actions/userActions'
import { CHANGE_PASSWORD_RESET } from '../../constants/userConstants'

const ChangePassword = () => {

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const loggedInUser = useSelector(state => state.auth).user;
    const { error, loading, isUpdated } = useSelector(state => state.updateUser);

    useEffect(() => {

        if (error) {
            toast.error(error)
            console.error(error)
            dispatch(clearErrors());
        }

        if (isUpdated) {
            toast.success("Password changed successfully!")
            navigate("/profile") 
            dispatch({
                type: CHANGE_PASSWORD_RESET
            })
        }

    }, [dispatch, isUpdated, error])

    const goBack = () => {
        navigate(-1)
    }
 
    const submitHandler = (e) => {
        e.preventDefault();

        if (!newPassword.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)) {
            return toast.error("Password does not match the required format.")
        }

        dispatch(changePassword({oldPassword : oldPassword, newPassword : newPassword}));
    }
    
    return (
        <Fragment>
            <Fragment>
                <Metadata title={'Change Password'} />
                <ToastContainer />
                <div className="container-fluid container-md">
                    <div className="row shadow-lg mid-container">
                        <div className="col-md-8 pt-5 pb-3 p-md-4">
                            <form className="px-2" onSubmit={submitHandler}>
                                <p onClick={goBack} className="breadcrumb text-xs mt-md-4">{"< Go Back"}</p>
                                <h3 className="text-sm bold-2 my-5">Change Password</h3>

                                {/* NEW PASSWORD */}
                                <div className="mb-3">
                                    <label htmlFor="oldPassword" className="form-label">Old Password</label>
                                    <input 
                                        type="password"
                                        name='oldPassword'
                                        minLength="6" 
                                        className="form-control" 
                                        id="oldPassword" 
                                        placeholder="**********"
                                        value={oldPassword}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        required
                                    />
                                </div>

                                {/* CONFIRM PASSWORD */}
                                <div className="mb-4">
                                    <label htmlFor="newPassword" className="form-label">New Password</label>
                                    <input 
                                        type="password" 
                                        name='newPassword'
                                        minLength="6"
                                        className="form-control" 
                                        id="newPassword" 
                                        placeholder="**********"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        required
                                    />
                                    {newPassword.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)
                                    ? <div id="passHelp" className="text-xxs mt-2 bold-2 green">Looks good!</div>
                                    : <div id="passHelp" className="text-xxs mt-2 bold-2 red">Password must have a minimum of 8 characters with at least 1 letter and 1 number.</div>
                                    }
                                </div>

                                <button 
                                    type="submit" 
                                    className="text-size-xs action-btn mb-5 w-100"
                                    disabled={loading ? true : false}
                                    >{loading ? "Changing password, please wait..." : "Change password"}
                                </button>
                            </form>
                        </div>
                        
                        <div className="col-md-4 d-none d-md-block text-end" style={{backgroundImage: "url('/images/signin-img.jpg')", backgroundSize: "cover", backgroundPosition: "center"}}>
                        </div>
                    </div>
                </div>    
            </Fragment>     
        </Fragment>
    )}
export default ChangePassword;