import React, { Fragment, useEffect } from 'react'
import Metadata from '../layout/metadata'

import { Link, useNavigate } from 'react-router-dom'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux'
import { getUserProfile, clearErrors } from '../../actions/userActions'

import Loader from '../layout/loader'

const UserProfile = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user, success, error, loading } = useSelector(state => state.userDetails)


    useEffect(() => {
        dispatch(getUserProfile())

        if (error) {
            toast.error("An error occurred, please check your internet connection, refresh, and try again.")
            dispatch(clearErrors())
        }

    }, [dispatch, error, success ])

    const goBack = () => {
        navigate(-1)
    }

    return (
        <Fragment>
            <Metadata title={'User Profile'}/>
            <ToastContainer />

            {loading ? <Loader/> :
            (
                <Fragment>
                    <div className="container-fluid container-md">
                        <div className="row shadow-lg mid-container">
                            <div className="col-md-8 py-5 p-md-4">
                                <p onClick={goBack} className="breadcrumb text-xs mt-md-4">{"< Go Back"}</p>
                                <h3 className="text-md mt-5 bold-2">{user.firstName} {user.lastName} {user.farmerStatus === 'Pending' || user.vendorStatus === 'Pending' ? "(Pending approval)" : `(${user.role})`}</h3>
                                <i className="d-block text-sm my-2">{user.credential}</i>
                                <p className="text-xs text-muted">Date created: {user.createdAt && user.createdAt.split('T')[0]}</p>
                                <div className="my-5">
                                    <Link to="/password/change" state={{source: "profile"}} className="action
                                    action-btn text-xs py-2">Change Password</Link>
                                </div>
                            </div>
                            
                            <div className="col-md-4 d-none d-md-block text-end" style={{backgroundImage: "url('/images/signin-img.jpg')", backgroundSize: "cover", backgroundPosition: "center"}}>
                            </div>
                        </div>
                    </div> 
                </Fragment>
            )}
        </Fragment>
    )
}

export default UserProfile