import { 
    NEW_COURSE_REQUEST,
    NEW_COURSE_SUCCESS,
    NEW_COURSE_RESET,
    NEW_COURSE_FAIL,
    UPDATE_COURSE_REQUEST,
    UPDATE_COURSE_SUCCESS,
    UPDATE_COURSE_FAIL,
    UPDATE_COURSE_RESET,
    ENROLL_COURSE_REQUEST,
    ENROLL_COURSE_SUCCESS,
    ENROLL_COURSE_FAIL,
    ENROLL_COURSE_RESET,
    GET_COURSES_REQUEST,
    GET_COURSES_SUCCESS,
    GET_COURSES_FAIL,
    COURSE_DETAILS_REQUEST,
    COURSE_DETAILS_SUCCESS,
    COURSE_DETAILS_FAIL,
    COURSE_DETAILS_RESET,
    CLEAR_ERRORS
} from '../constants/courseConstants'

export const getCoursesReducer = (state = { courses:[] }, action) => {
switch(action.type) {
    case GET_COURSES_REQUEST:
        return {
            loading: true,
            courses: []
        }

    case GET_COURSES_SUCCESS:
        return {
            loading: false,
            courses: action.payload
        }

    case GET_COURSES_FAIL:
        return {
            loading: false,
            error: action.payload
        }
    case CLEAR_ERRORS:
        return {
            ...state,
            error: null
        }
    default:
        return state;
}
}

export const newCourseReducer = (state = { newCourse: {} }, action) => {
switch(action.type) {
    case NEW_COURSE_REQUEST:
    case UPDATE_COURSE_REQUEST:
        return {
            ...state,
            loading: true,
        }
    case NEW_COURSE_SUCCESS:
    case UPDATE_COURSE_SUCCESS:
        return {
            loading: false,
            success: action.payload.success,
            newCourse: action.payload.newCourse

        }

    case NEW_COURSE_FAIL:
    case UPDATE_COURSE_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload
        }

    case NEW_COURSE_RESET:
    case UPDATE_COURSE_RESET:
        return {
            ...state,
            success: false,
        }
    case CLEAR_ERRORS:
        return {
            ...state,
            error: null
        }
    default:
        return state;
}
}

export const enrollCourseReducer = (state = { newCourse: {} }, action) => {
    switch(action.type) {
        case ENROLL_COURSE_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case ENROLL_COURSE_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                newCourse: action.payload.newCourse
    
            }

        case ENROLL_COURSE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
    
        case ENROLL_COURSE_RESET:
            return {
                ...state,
                success: false,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state;
    }
    }

export const courseDetailsReducer = (state = {course: {}}, action) => {
    switch (action.type) {
        case COURSE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case COURSE_DETAILS_SUCCESS:
            return {
                loading: false,
                course: action.payload,
                success: true
            }
        case COURSE_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        case COURSE_DETAILS_RESET:
            return {
                loading: false,
                course: {}
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }
        default:
            return state
    }
}